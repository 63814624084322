import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Typography, Row } from 'antd'
import CardHomeAdministator from '../../Components/HomeAdministrator/CardHomeAdministator'
import SideMenu from '../../Components/Global/SideMenu'
import { GetDataHomeAdministratorReducer } from '../../Redux/Actions/HomeAdministrator/HomeAdministrator'
import { funPermisosObtenidosHome } from '../../Functions/funPermisos'

const HomeAdministrator = () => {
    
    const { Title } = Typography
    const  dispatch = useDispatch()

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const { 
        rex_data_permissions_user
    } = useSelector(({loginGrow}) => loginGrow)

    const {
        rex_data_home_administrator
    } = useSelector(({homeAdministrator}) => homeAdministrator)

    const getDataHomeAdministrator = async () => {
        dispatch(GetDataHomeAdministratorReducer())
    }

    useEffect(() => {
        getDataHomeAdministrator()
    }, [])

    return (
        <div className='Container-Main Container-Home-Administrador'>
            
            
            <Row className='Home-Administrator-Container-Title'>
                <Title level={2} className='Home-Administrator-Title'>Administrador</Title>
            </Row>

            <Row style={{display:'flex', justifyContent:'space-between', rowGap: '20px'}} gutter={20}>
                {/* {
                    funPermisosObtenidosHome(
                        rex_data_permissions_user, 'Tipos de perfiles',
                        <CardHomeAdministator 
                            title='Tipos de perfiles'
                            text='En esta sección encontrará la lista de los tipos de perfil existentes y también se podrá crear un nuevo perfil.'
                            url='/administrator/profile-types'
                        />    
                    )
                } */}

                {
                    funPermisosObtenidosHome(
                        rex_data_permissions_user, 'Lista de usuarios',
                        <CardHomeAdministator 
                            title='Lista de Usuarios'
                            text='En esta sección encontrará la lista de los lista de usuarios existentes y también se podrá crear un nuevo usuario.'
                            url='/administrator/users'
                        />
                    )
                }

                {
                    funPermisosObtenidosHome(
                        rex_data_permissions_user, 'Modulos',
                        <CardHomeAdministator 
                            title='Módulos'
                            text='En esta sección encontrará todos los módulos de las categorías existentes y los módulos del menú.'
                            url='/administrator/modules'
                        />
                    )
                }

                {
                    funPermisosObtenidosHome(
                        rex_data_permissions_user, 'Control de datos',
                        <CardHomeAdministator 
                            title='Control de Datos'
                            text='En esta sección se podrá gestionar el control de todos los archivos que se viasualizan en la vista descarga de data.'
                            url='/administrator/data-control'
                        />
                    )
                }

                {
                    funPermisosObtenidosHome(
                        rex_data_permissions_user, 'Control de archivos',
                        <CardHomeAdministator 
                            title='Control de Archivos'
                            text='En esta sección se pordrá gestionar la carga de archivos de la vista carga de archivos.'
                            url='/administrator/file-control'
                        />
                    )
                }

                {
                    funPermisosObtenidosHome(
                        rex_data_permissions_user, 'Control de Paises',
                        <CardHomeAdministator 
                            title='Paises'
                            text='En esta sección se podrá gestionar los paises.'
                            url='/administrator/country'
                        />
                    )
                }

                {
                    funPermisosObtenidosHome(
                        rex_data_permissions_user, 'Administrador de Plataforma',
                        <CardHomeAdministator 
                            title='Administrador de Plataforma'
                            text='En esta sección se podrá gestionar algunas secciones de la plataforma.'
                            url='/administrator/platform-administration'
                        />
                    )
                }

            </Row>

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}

export default HomeAdministrator