import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from "react-router-dom"
import { Row, Col, Typography, Form, Input, Spin } from 'antd'
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons'
import { GetCountriesLoginReducer, LoginUsuarioLoginGrowReducer } from '../../Redux/Actions/LoginGrow/LoginGrow'
import SoundButton from '../../Assets/Audio/sonidoOption.mp3'
import ButtonBackMenu from '../../Components/Menu/ButtonBackMenu'

const LoginGrow = () => {

    const { Title } = Typography
    const dispatch = useDispatch()

    const valuePaiRestore = localStorage.getItem('pairestore')

    const audio = new Audio(SoundButton)
    const [ clickButton, setClickButton ] = useState(false)

    const { 
        rex_error_user_login,
        rex_loading_user_login,
        rex_data_countries_login,
    } = useSelector(({loginGrow}) => loginGrow)

    const reproduceSound = () => {
        audio.play()
    }

    const navigate = useNavigate()

    const [ usuPaiid, setUsuPaiid ] = useState(valuePaiRestore ? valuePaiRestore : 1)
    const [ focusInputPassword, setFocusInputPassword ] = useState(false)
    const [ focusInputEmail, setFocusInputEmail ] = useState(false)
    const [ tamanioPassword, setTamanioPassword] = useState(false)
    const [ tamanioEmail, setTamanioEmail] = useState(false)

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: '22px',
                color: '#fff'
            }}
            spin
        />
    )

    const onFinish = async (values)  => {
        values['req_paiid'] = usuPaiid
        await dispatch(LoginUsuarioLoginGrowReducer(values)) && navigate('/home')
    }

    const lengthInput = (e, type) => {
        let lengthValue = e.target.value

        if(lengthValue.length  > 0){
            if(type == 2){
                setTamanioPassword(true)
            }else{
                setTamanioEmail(true)
            }
        }else{
            if(type == 2){
                setTamanioPassword(false)
            }else{
                setTamanioEmail(false)
            }
        }
    }

    useEffect(() => {
        if(rex_data_countries_login.length == 0){
            dispatch(GetCountriesLoginReducer())
        }
    }, [])
    
    return (
        <Row className="Container-Image-Gif" style={{height: '100vh'}}>
            <Col lg={15} md={24} xs={24} className="Col-Image-Login-Grow"></Col>

            <Col lg={9} md={24} xs={24} className="Col-Login-Grow">
                <div className="Container-Login-Grow">
                    <ButtonBackMenu />
                    <div className="Container-Filter">
                        {
                            rex_data_countries_login.map((cou) => (
                                <div className='Container-Country-Select'>
                                    {
                                        usuPaiid == cou.paiid && 
                                            <div className="Country-Text">
                                                {cou.painombre ? (cou.painombre).toString().slice(0, 2).toUpperCase() : ''}
                                            </div>
                                    }
                                    <div
                                        onClick={() => {
                                            localStorage.setItem("pairestore", cou.paiid)
                                            setUsuPaiid(cou.paiid)
                                        }}
                                        className={
                                            usuPaiid == cou.paiid
                                            ?"Country-Select-Selected-LoginGrow"
                                            :"Country-Select-LoginGrow"
                                        }
                                    >
                                        <div className='Container-Country-LoginGrow'>
                                            <img
                                                src={cou.paiiconocircular} 
                                                className={`Image-Filter-Login-Grow ${cou.paiid == 7 && 'Country-Flag-Andes-Login-Grow'}`} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <Title 
                        level={2}
                        className="Title-Login-Grow"
                    >
                        ¡Bienvenido!
                    </Title>
                    <Form
                        name="basic"
                        onFinish={onFinish}
                        autoComplete='on'
                    >
                        <Form.Item
                            name="req_usucorreo"
                            style={{marginBottom: '10px'}}                        
                        >
                            <div style={{ display:'flex', flexDirection:'column', position:'relative'}}>
                                <input 
                                    onFocus={()=> {
                                        setFocusInputEmail(true)
                                    }}
                                    onBlur={() =>{
                                        if(tamanioEmail == false){
                                            setFocusInputEmail(false)
                                        }
                                    }}
                                    onChange={(e) => lengthInput(e, 1)}
                                    className={`
                                        ${focusInputEmail == true 
                                        ? "Input-LoginFocus" 
                                        : "Input-Login"}
                                        ${Object.keys(rex_error_user_login).length > 0 
                                            ? rex_error_user_login.type == "Email" ? " Input-Error" : "" : ""}
                                    `}
                                    id="email"
                                    style={{backgroundColor: '#ffffff'}}
                                />
                                <label class={`${focusInputEmail ? 'Label-Input-Login-Grow-Focus':'Label-Input-Login-Grow' }`}>Email Address</label>
                            </div>
                        </Form.Item>

                        <Form.Item
                            name="req_usucontrasenia"
                            style={{marginBottom: '5px', display:'flex', flexDirection:'column', position:'relative', margin:'20px 0 10px 0'}}
                        >
                            <div style={{ display:'flex', flexDirection:'column', position:'relative'}}>
                            <Input.Password
                                onFocus={()=> {
                                    setFocusInputPassword(true)
                                }}
                                onBlur={() =>{
                                    if(tamanioPassword == false){
                                        setFocusInputPassword(false)
                                    }
                                }}
                                className={`
                                    ${focusInputPassword == true 
                                    ? "Input-LoginFocus"
                                    : "Input-Login"}
                                    ${Object.keys(rex_error_user_login).length > 0 
                                        ? rex_error_user_login.type == "Contraseña" ? " Input-Error" : "" : ""}`}
                                placeholder=""
                                onChange={(e) => lengthInput(e, 2)}
                            />
                            <label class={`${focusInputPassword ? 'Label-Input-Login-Grow-Focus':'Label-Input-Login-Grow' }`}>Password</label>
                            </div>
                        </Form.Item>
                        {
                            Object.keys(rex_error_user_login).length > 0
                            &&  rex_error_user_login.message.map(error => <p className="Password-Incorrect">{error}</p>)
                        }

                        <div className="Container-Password-Link">
                            <Link to="/recover-password">
                                <p className="Password-Link">Recuperar contraseña</p>
                            </Link>
                        </div>
                        
                        <Form.Item 
                            className="Container-Button-Password"
                        >
                            <Spin 
                                indicator={antIcon} 
                                spinning={rex_loading_user_login ? true : false}
                                wrapperClassName="Loading-Button-Form"
                            >
                            <button
                                className="Button-Login-Grow" 
                                htmlType="submit"
                                onClick={()=> {
                                    reproduceSound()
                                    setClickButton(true)
                                }}
                                onMouseEnter={() => reproduceSound()}
                                onMouseLeave={() => setClickButton(false)}
                            >
                                <span style={{opacity: rex_loading_user_login ? '0' : '', zIndex:"1"}}>Iniciar Sesión</span>
                                <span style={{opacity: rex_loading_user_login ? '0' : '', zIndex:"1"}} className={`Text-Span-Login-Grow ${clickButton?'Button-Text-Color':''}`}>Iniciar Sesión</span>
                            
                                <div className={`${!clickButton ?'Button-Background-Login-Grow':'Button-Background-Login-Grow-Active'}`}></div>
                                <div className='Button-Corner Button-Top-Left-Login-Grow'></div>
                                <div className='Button-Corner Button-Top-Right-Login-Grow'></div>
                                <div className='Button-Corner Button-Bottom-Right-Login-Grow'></div>
                                <div className='Button-Corner Button-Bottom-Left-Login-Grow'></div>
                            </button>
                            </Spin>
                        </Form.Item>

                        <Link to="/login">
                            <div className="Container-Return-Login">
                                <ArrowLeftOutlined className="Icon-Return-Login" />
                                <p className="Text-Return-Login">Regresar a todas las opciones</p>
                            </div>
                        </Link>
                    </Form>
                </div>
            </Col>
        </Row>
    )
}

export default LoginGrow