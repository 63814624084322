import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col, Card, Form, Input, Typography, Tooltip, Modal } from 'antd'
import { PlusCircleOutlined, MinusOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import FormNewSubSubModules from './FormNewSubSubModules'
import { AddDataSubModuleSubsubmoduleReducer, AddSubSubModuleReducer, ChangeExpandibleSubModuleReducer, ChangeValueFormReducer, EditSubModuleReducer, RemoveSubModuleReducer, RemoveSubSubModuleNewReducer, StoreSubModuleReducer } from '../../../Redux/Actions/Modules/CreateEditModules'
import { DeleteDataSubModuleReducer } from '../../../Redux/Actions/Modules/Modules'

const FormNewSubModules = (props) => {
    
    const value = props.value
    const { Title } = Typography
    const { confirm } = Modal
    const dispatch = useDispatch()
    const { id } = useParams()
    const [ sendCreate, setSendCreate ] = useState(false)

    const DeleteSubModule = (key) => {
        confirm({
            title: 'Confirmar',
            zIndex: 100000000,
            icon: <></>,
            content: '¿Estás seguro de eliminar el submódulo?',
            width: 260,
            centered: true,
            className: 'Modal-Delete',
            okButtonProps: {className: 'Confirm-Modal-Delete'},
            okText: <>
                        <span className="First-Confirm">Confirmar</span>
                        <span className="Last-Confirm">Confirmar</span>
                    </>,
            cancelButtonProps: {className: 'Cancel-Modal-Delete'},
            cancelText: <>
                            <span className="First-Cancel">Cancelar</span>
                            <span className="Last-Cancel">Cancelar</span>
                        </>,
            async onOk() {
                if(value.smosubmodulosnew){
                    dispatch(RemoveSubModuleReducer(key))
                }else{
                    const response = await dispatch(DeleteDataSubModuleReducer(value.smoid))
                    if(response){
                        dispatch(RemoveSubModuleReducer(key))
                    }
                }
            },
            onCancel() {
            },
        })
    }

    const GetNameSlug = (value, mod) => {
        const formatName = (value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s/g, '')
        const nameSlug = mod.smomodcountry + mod.smomodname + formatName
        return nameSlug
    }

    return(
        <Card 
            className={`Card-Form-Submodules ${!value.expandible ? 'Expandible-Submodules' : ''} ${value.validation ? 'Error-Submodules' : ''}`}
            style={{height: value.expandible ? '100%' : '60px', overflowY: 'hidden', transitionDuration: '.5s'}}
        >
            <Row style={{marginBottom: !value.expandible ? '15px' : '12px'}}>
                <Col span={22} style={{display: 'flex', alignItems: !value.expandible ? 'center' : 'start'}}>
                    <Title
                        level={3}
                        className="Title-Submodules"
                    >
                        {
                            value.smosubmodulosnew
                            ?"Nuevo Submódulo"
                            :"Editar Submódulo"
                        }
                    </Title>
                </Col>
                <Col span={2} style={{display: 'flex', justifyContent: 'end', alignItems: 'center', columnGap: '10px'}}>
                    <Tooltip
                        placement='bottom'
                        title={value.expandible ? 'Minimizar Sub Módulo' : 'Maximizar Sub Módulo'}
                        color='#3B72FF'
                        showArrow={false}
                        overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                    >
                        {
                            value.expandible
                            ?<MinusOutlined 
                                onClick={() => dispatch(ChangeExpandibleSubModuleReducer(false, value.key))} 
                                className="Icon-Min-Max-Submodules" 
                            />
                            : <PlusOutlined 
                                onClick={() => dispatch(ChangeExpandibleSubModuleReducer(true, value.key))} 
                                className="Icon-Min-Max-Submodules" 
                            />
                        }
                    </Tooltip>
                    <Tooltip
                        placement='bottom'
                        title='Elminiar Sub Módulo'
                        color='#3B72FF'
                        showArrow={false}
                        overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                    >
                        <div onClick={() => DeleteSubModule(value.key)} className="Icon-Delete-Submodules"></div>
                    </Tooltip>
                </Col>
            </Row>
            <Form
                className="Form-Submodules"
                layout="vertical"
                autoComplete="off"
                onValuesChange={false}
            >
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item 
                            label="Nombre del submódulo"
                            className="Form-Item-Submodules"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                        >
                            <Input 
                                onChange={(e) => {
                                    let nameRoute = (e.target.value).toLowerCase().split(' ').join('-')
                                    dispatch(StoreSubModuleReducer(e.target.value, value.key, "subnombre"))
                                    dispatch(StoreSubModuleReducer(nameRoute, value.key, "subroute"))
                                    dispatch(StoreSubModuleReducer(GetNameSlug(e.target.value, value), value.key, "subslug"))
                                    dispatch(StoreSubModuleReducer((e.target.value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s/g, ''), value.key, "subslugtext"))
                                }} 
                                placeholder="Ingresar nombre" 
                                value={value.smonombre}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item 
                            label="Ruta de submódulo"
                            className="Form-Item-Submodules"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                        >
                            <Input 
                                onChange={(e) => {                                            
                                    // dispatch(StoreSubModuleReducer(e.target.value, value.key, "subroute"))
                                }}
                                disabled={true}
                                // placeholder="Ingresar enlace" 
                                value={value.smoruta}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item 
                            label="Enlace Power Bi"
                            className="Form-Item-Submodules"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                        >
                            <Input 
                                onChange={(e) => dispatch(StoreSubModuleReducer(e.target.value, value.key, "sublink"))} 
                                placeholder="Ingresar ruta" 
                                value={value.smopowerbi}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item 
                            label="Slug del permiso"
                            className="Form-Item-Submodules"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                        >
                            <Input 
                                onChange={(e) => {}} 
                                placeholder="Ingresar slug" 
                                value={value.smoslug}
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <div 
                            className="Container-Create-SubSubmodules-Submodules"
                            onClick={() => dispatch(AddSubSubModuleReducer(value.key))}
                        >
                            <PlusCircleOutlined className="Icon-Create-SubSubmodules-Submodules" /><p className="Create-SubSubmodules-Submodules">Agregar sub sub módulo</p>
                        </div>
                    </Col>
                    {
                        value.ssmsubmodulos.length > 0 &&  
                        value.ssmsubmodulos.map((subsubmod, index) => 
                            <Col span={24} style={{marginTop: '12px'}} key={index}>
                                <FormNewSubSubModules 
                                    valuessubsub={subsubmod}
                                    submodulekey={value.key}
                                />
                            </Col>
                        )
                    }
                    {
                        id &&
                        <Col span={24} className="Container-Button-SubModules">
                            <button 
                                className="Button-Cancel-SubModules"
                                onClick={async () => {
                                    if(value.smosubmodulosnew){
                                        dispatch(RemoveSubModuleReducer(value.key))
                                    }else{
                                        dispatch(RemoveSubSubModuleNewReducer(value.key))
                                        dispatch(ChangeValueFormReducer(value.key, false))
                                    }
                                }}
                            >
                                <span className="Text-Cancel">Cancelar</span>
                                <span className="Text-Cancel-Effect">Cancelar</span>
                            </button>
                            <button 
                                onClick={async () => {
                                    setSendCreate(true)
                                    const respuesta = await dispatch(AddDataSubModuleSubsubmoduleReducer(value.key))
                                    if(respuesta){
                                        await dispatch(EditSubModuleReducer(value.key))
                                    }
                                    setSendCreate(false)
                                }} 
                                className="Button-New-SubModules"
                            >
                                {
                                    sendCreate
                                    ?
                                        <div style={{padding:'0 20px'}}>
                                            <LoadingOutlined />
                                        </div>
                                    :   <>
                                            <span className="Text-New">Guardar</span>
                                            <span className="Text-New-Effect">Guardar</span>
                                        </>
                                }
                            </button>
                        </Col>
                    }
                </Row>
            </Form>
        </Card>
    )
}

export default FormNewSubModules