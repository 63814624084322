import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { GetDataModulesHomeReducer } from '../../Redux/Actions/Home/Home'
import {  HashLink as RouterHashLink } from "react-router-hash-link"
import { RegisterUsersConnectedHomeModulesReducer, SelectModuleActiveClickHomeModulesReducer, SelectModuleActiveHoverHomeModulesReducer, SelectModuleSelectedHomeModulesReducer } from '../../Redux/Actions/HomeModules/HomeModules'
import { Row, Col, Tabs } from 'antd'
import socket from '../../App/socket'
import SoundOption from '../../Assets/Audio/sonidoOptionNew.mp3'
import CardSubmodule1 from '../../Components/CardHomeModule/CardSubmodule1/CardSubmodule1'
import CardSubModule2 from '../../Components/CardHomeModule/CardSubmodule2/CardSubModule2'
import CardSubModule3 from '../../Components/CardHomeModule/CardSubmodule3/CardSubModule3'
import CardSubModule4 from '../../Components/CardHomeModule/CardSubmodule4/CardSubModule4'
import CardSubModule5 from '../../Components/CardHomeModule/CardSubmodule5/CardSubModule5'
import CardSubModule6 from '../../Components/CardHomeModule/CardSubmodule6/CardSubModule6'
import CardSubModule7 from '../../Components/CardHomeModule/CardSubmodule7/CardSubModule7'
import CardSubmodule8 from '../../Components/CardHomeModule/CardSubmodule8/CardSubModule8'
import SideMenu from '../../Components/Global/SideMenu'

const HomeModules = () => {
    const dispatch = useDispatch()
    const audio = new Audio(SoundOption)

    const {
        rex_data_modulos_dashboards_total,
    } = useSelector(({dashboards}) => dashboards)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const { 
        rex_data_user_logingrow
    } = useSelector(({loginGrow}) => loginGrow)

    const {
        rex_users_home_modules_connected,
        rex_module_selected_home_modules,
        rex_module_active_click_home_modules,
        rex_module_active_hover_home_modules,
    } = useSelector(({homeModules}) => homeModules)

    const reproduceSound = () => {
        audio.play()
    }

    useEffect(() => {
        dispatch(GetDataModulesHomeReducer())
    }, [])

    useEffect(() => {
        socket.on('update-category', () => {
            dispatch(GetDataModulesHomeReducer())
        })
    },[])

    useEffect(() => {
        socket.on('get-users-modules', (data_users) => {
            dispatch(RegisterUsersConnectedHomeModulesReducer(data_users))
        })

        return () => {
            socket.emit('unreg-modules', { module: 'status'}, {usuusuario: rex_data_user_logingrow.usuusuario} )
            socket.off('get-users-modules')
        }
    }, [])

    return(
        <div className="Container-Main" style={{paddingBottom: '5px', paddingTop: '52px'}}>
            <Row style={{marginBottom: '15px'}}>
                <Col span={24}>
                    <Tabs
                        className="Module-Tabs"
                        activeKey={rex_module_selected_home_modules}
                        tabPosition="left"
                        onChange={(e) => {
                            dispatch(SelectModuleSelectedHomeModulesReducer(e))
                            dispatch(SelectModuleActiveClickHomeModulesReducer(e))
                        }}
                        tabBarExtraContent={{
                            left:   <RouterHashLink to="/home#Category" style={{position: 'relative', zIndex: 1}}>
                                        <div className="Title-Module">
                                            Categorías
                                        </div>
                                    </RouterHashLink>
                        }}
                        items={
                            rex_data_modulos_dashboards_total.map(mod => {
                                return {
                                    key: mod.modid,
                                    label: 
                                    <div 
                                        className="Title-Module-Tabs"
                                        onMouseEnter={() => reproduceSound()}
                                        onMouseOver={() => {
                                            dispatch(SelectModuleActiveHoverHomeModulesReducer(mod.modid))
                                        }}
                                        onMouseLeave={() => {
                                            dispatch(SelectModuleActiveHoverHomeModulesReducer(null))
                                        }}
                                    >
                                        <Link to="/home-modules" state={mod.modid}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <span
                                                    className="Icon-Title-Module-Tabs" 
                                                    style={{
                                                        position: 'absolute',
                                                        width: rex_module_active_click_home_modules == mod.modid 
                                                            ? '23px' 
                                                            : rex_module_active_hover_home_modules == mod.modid
                                                                ? '23px' 
                                                                : '0',

                                                        transition: rex_module_active_click_home_modules == mod.modid  
                                                            ? 'width .3s .6s' 
                                                            : rex_module_active_hover_home_modules == mod.modid
                                                                ? 'width .3s .6s'
                                                                : 'width .3s',
                                                        zIndex: 2,
                                                    }}
                                                ></span>
                                                <span 
                                                    className="Icon-Title-Module-Tabs" 
                                                    style={{
                                                        position: 'absolute',
                                                        width: rex_module_active_click_home_modules == mod.modid 
                                                            ? '6px' 
                                                            : rex_module_active_hover_home_modules == mod.modid
                                                                ? '6px' 
                                                                : '0', 
                                                        backgroundColor: '#2AD295',
                                                        transition: 'width .5s',
                                                        zIndex: 1,
                                                    }}
                                                ></span>
                                                <span
                                                    style={{
                                                        marginLeft: rex_module_active_click_home_modules == mod.modid
                                                            ? '17px'
                                                            : rex_module_active_hover_home_modules == mod.modid
                                                                ? '17px'
                                                                : '0',
                                                        paddingLeft: rex_module_active_click_home_modules == mod.modid
                                                            ? '11px'
                                                            : rex_module_active_hover_home_modules == mod.modid
                                                                ? '11px'
                                                                : '0',
                                                        transition: rex_module_active_click_home_modules == mod.modid
                                                            ? 'margin-left .3s .6s, padding-left .5s'
                                                            : rex_module_active_hover_home_modules == mod.modid
                                                                ? 'margin-left .3s .6s, padding-left .5s'
                                                                : 'margin-left .5s, padding-left .5s .2s',
                                                    }}
                                                >
                                                    {mod.modnombre}
                                                </span>
                                            </div>
                                        </Link>
                                    </div>,
                                    children: mod.children.length == 1
                                        ? <CardSubmodule1 module={mod} submodule={mod.children} titlemodule={mod.modnombre} activeModule={rex_module_selected_home_modules} modid={mod.modid} />
                                        : mod.children.length == 2
                                        ? <CardSubModule2 module={mod} submodule={mod.children} titlemodule={mod.modnombre} activeModule={rex_module_selected_home_modules} modid={mod.modid} />
                                        : mod.children.length == 3
                                        ? <CardSubModule3 module={mod} submodule={mod.children} titlemodule={mod.modnombre} activeModule={rex_module_selected_home_modules} modid={mod.modid} />
                                        : mod.children.length == 4
                                        ? <CardSubModule4 module={mod} submodule={mod.children} titlemodule={mod.modnombre} activeModule={rex_module_selected_home_modules} modid={mod.modid} />
                                        : mod.children.length == 5
                                        ? <CardSubModule5 module={mod} submodule={mod.children} titlemodule={mod.modnombre} activeModule={rex_module_selected_home_modules} modid={mod.modid} />
                                        : mod.children.length == 6
                                        ? <CardSubModule6 module={mod} submodule={mod.children} titlemodule={mod.modnombre} activeModule={rex_module_selected_home_modules} modid={mod.modid} />
                                        : mod.children.length == 7
                                        ? <CardSubModule7 module={mod} submodule={mod.children} titlemodule={mod.modnombre} activeModule={rex_module_selected_home_modules} modid={mod.modid} />
                                        : mod.children.length == 8
                                        && <CardSubmodule8 module={mod} submodule={mod.children} titlemodule={mod.modnombre} activeModule={rex_module_selected_home_modules} modid={mod.modid} />
                                }
                            })
                        }
                    />
                </Col>
            </Row>
            {
                rex_users_home_modules_connected && 
                    <SideMenu
                        usersCurrentView  = {rex_users_home_modules_connected}
                        usersAllConnected = {rex_data_users_online}
                    />
            }
        </div>
    )
}
export default HomeModules