import React, { useState, useEffect, useRef } from 'react'
import { Col, Row, Drawer, Button } from 'antd'
import RightMenu from './RightMenu'
import StatusActiveIcon from '../../Assets/Images/Home/active_user.gif'
import StatusInactiveIcon from '../../Assets/Images/Home/inactive_user.gif'
import socket from '../../App/socket'
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'
import LogoWeb from '../../Assets/Images/Home/logos-web.png'
import ImageRobot from '../../Assets/Images/Home/imageRobot.webp'
import { 
    LoadingOutlined,
} from '@ant-design/icons'
import {
    ValidationUserReducer
} from '../../Redux/Actions/Validations/Validations'
import { GetUsersOnlineReducer } from '../../Redux/Actions/Users/Users'
import { GetDataModulesHomeReducer } from '../../Redux/Actions/Home/Home'
import { GetCardsFileUpload } from '../../Redux/Actions/FileUpload/FileUpload'
import { ObtenerArchivosDownloadDataReducer } from '../../Redux/Actions/DownloadData/DownloadData'
import { GetDataAreasStatusReducer } from '../../Redux/Actions/Status/Status'
import { ObtenerPaisesGetDataReducer } from '../../Redux/Actions/GetData/Paises/Paises'
import { GetDataMenuReducer } from '../../Redux/Actions/GetData/Menu/Menu'
import { GetDataPlatformAdministrationReducer } from '../../Redux/Actions/GetData/PlatformAdministration/PlatformAdministration'

function Top({buttonBack, scrollY}) {

    const { 
        rex_data_user_logingrow
    } = useSelector(({loginGrow}) => loginGrow)

    const { 
        rex_data_country_total
    } = useSelector(({paises}) => paises)

    const { 
        rex_get_data_platform_administration
    } = useSelector(({getPlatformAdministration}) => getPlatformAdministration)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const [ selectCountry, setSelectCountry ] = useState(0)
    const [ nameClassButton, setNameClassButton ] = useState('not-active')
    const [ visible, setVisible ] = useState(false)
    const [ menuAbierto, setMenuAbierto] = useState(false)
    const [ topMenu, setTopMenu] = useState(false)
    const [ loadingCountry, setLoadingCountry ] = useState(false)

    const [ statusUser, setStatusUser ] = useState(true)
    
    //Tiempo inactividad usuario en milisegundos
    const timeout       = 300000

    //Tiempo inactividad sesión
    // const timeoutSesion = 5000
    const timeoutSesion = 1200000

    const onIdle = () => {
        socket.emit('reg-activity-user', {usuusuario: rex_data_user_logingrow.usuusuario}, false)
        setStatusUser(false)
    }

    const onIdleLogout = () => {
        console.log("No Disconnect")
        localStorage.clear()
        socket.emit('logout', rex_data_user_logingrow.usuusuario)
    }
    
    const onActive = () => {
        socket.emit('reg-activity-user', {usuusuario: rex_data_user_logingrow.usuusuario}, true);
        setStatusUser(true)
    }

    const onActiveLogout = () => {}
    
    const showDrawer = () => {
        setMenuAbierto(!menuAbierto)
        setVisible(!visible)
    }

    const ValidateUser = async () => {
        const validate_user = await dispatch(ValidationUserReducer())
        if(!validate_user){
            navigate('/login')
        }
    }

    const dataMenu = async () => {
        dispatch(GetDataPlatformAdministrationReducer())
        await dispatch(ObtenerPaisesGetDataReducer())
        dispatch(GetDataMenuReducer())
    }

    const getNameCountry = () => {
        let name = ""

        const couUsu = localStorage.getItem("usupais")
        const findCountry = rex_data_country_total.find(cou => cou.paiid == couUsu)
        name = findCountry.painombre ? (findCountry.painombre).toString().slice(0, 2).toUpperCase() : ''

        return name
    }
    
    const idleTimerActivity = useIdleTimer({
        onIdle: () => onIdle(),
        onActive,
        timeout,
        throttle: 500
    })

    const idleTimerSesion = useIdleTimer({
        onIdle: () => onIdleLogout(),
        onActive: onActiveLogout,
        timeout: timeoutSesion,
        throttle: 500
    })
    
    useEffect(() => {
        if(localStorage.getItem('usutoken')){
            dataMenu()
            socket.on('validate-user', () => {
                if(localStorage.getItem('usutoken')){
                    ValidateUser()
                }else{
                    navigate('/login')
                }
            })
            
            socket.on('get-users-connected', ( users_online ) => {
                dispatch(GetUsersOnlineReducer( users_online ))
            })
    
            return () => {
                socket.off('validate-user')
                socket.off('users-connected')
            }
        }
    }, [])

    return (
        <>
        <div className="Container-Round-Menu">
            <div className={`Round-Menu-First ${topMenu ? 'Round-Active-First' : ''}`}></div>
            <div className={`Round-Menu-Second ${topMenu ? 'Round-Active-Second' : ''}`}></div>
            <div className={`Round-Menu-Third ${topMenu ? 'Round-Active-Third' : ''}`}></div>
            <div className={`Round-Menu-Forth ${topMenu ? 'Round-Active-Forth' : ''}`}></div>
        </div>
        <div className='Top-Container-Main'>
            <div className="Container-Menu"></div>

            <div
                className={`Top-Container-Burger-Menu ${nameClassButton == 'active' &&'Top-Menu-Active'} active-opacity `}
                onClick={() => {
                    showDrawer()
                    nameClassButton == 'active' ? setNameClassButton('not-active') : setNameClassButton('active')
                    setTopMenu(!topMenu)
                }}
            >
                <div className={`Top-Burger-Menu ${nameClassButton}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            
            <div 
                className='Menu-Container-Button-Logo'
                onClick={() => {
                    navigate('/')
                }}
            >
                <div className={`${buttonBack && 'Menu-Top-Logo-Margin'}`}>
                    <img
                        height={24}
                        src={
                            Object.keys(rex_get_data_platform_administration).length > 0
                            ?   rex_get_data_platform_administration.aplimagenseleccionado
                                ?   rex_get_data_platform_administration.aplimagenseleccionado
                                :   LogoWeb
                            :   LogoWeb
                        }
                    />
                </div>
            </div>
            <div className='Menu-User-Status-Menu'>
                <Button 
                    size='small' 
                    type='primary'
                    style={{
                        fontSize:'11px',
                        margin:'0 10px',
                        backgroundColor:'#2AD295',
                        display:'flex',
                        alignItems:'center'
                    }}
                    onClick={() => {
                        navigate('/ia/iaplus')
                    }}
                >
                    <img src={ImageRobot} style={{marginRight:'6px'}} height='16' width='16'/>
                    IA PLUS</Button>
                <div className='Menu-User-Container-Icon'>
                    <img className='Menu-User-Icon' src={statusUser? StatusActiveIcon : StatusInactiveIcon}/>
                </div>
                {
                    rex_data_user_logingrow.perpersonas 
                    ?   <>
                            <span className='Menu-User-Name-Menu Menu-User-Full-Name'>{rex_data_user_logingrow.perpersonas.webName}</span>
                        </> 
                    : <span></span>
                }
            </div>

            <Drawer
                title={
                    <Row>
                        <Col span={20} className="Col-Top-Select">
                            <Row className='Top-Select-Country'>
                                {
                                    rex_data_country_total.filter(cou => cou.estid == 1).map((cou, indexCou) => {
                                            return <Col key={indexCou} className='Container-Countrie-Select-Top'>
                                            <div 
                                                onClick={async () => {
                                                    if(selectCountry != cou.paiid){
                                                        navigate('/home')
                                                        setSelectCountry(cou.paiid)
                                                        localStorage.setItem('usupais', cou.paiid)
                                                        setLoadingCountry(true)
                                                        await dispatch(GetDataModulesHomeReducer(true))
                                                        await dispatch(GetCardsFileUpload())
                                                        await dispatch(ObtenerArchivosDownloadDataReducer())
                                                        await dispatch(GetDataAreasStatusReducer())
                                                        await dispatch(GetDataMenuReducer())
                                                        setLoadingCountry(false)
                                                    }
                                                }}
                                                className={
                                                    rex_data_user_logingrow?.countriesPem?.includes(cou.paiid) ?
                                                    selectCountry == 0
                                                    ?   localStorage.getItem('usupais') == cou.paiid
                                                        ?'Countrie-Select-Top-Selected'
                                                        :'Countrie-Select-Top'
                                                    :   selectCountry == cou.paiid
                                                        ?'Countrie-Select-Top-Selected'
                                                        :'Countrie-Select-Top'
                                                    : 'Disable-Country-Select'
                                                }
                                            >
                                                <div style={{display:"flex", flexDirection:"column"}}>
                                                {
                                                    localStorage.getItem('usupais') == cou.paiid
                                                    ? <div className='Prefix-Country-Selected'>{getNameCountry()}</div>
                                                    : null
                                                }
                                                    <div
                                                        className='Container-Country-Top'
                                                    >
                                                        {
                                                            loadingCountry && selectCountry == cou.paiid
                                                            ?   <div className='Container-Loading-Country'>
                                                                    <LoadingOutlined/>
                                                                </div>
                                                            :   null   
                                                        }
                                                        <img 
                                                            className={`Image-Top-Countries  ${cou.paiid == 4 && 'Flag-Top-Andes'}`}
                                                            src={cou.paiiconocircular}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </Col>
                                        // }
                                    })
                                }

                            </Row>
                        </Col>
                        <Col span={4}>
                        </Col>
                    </Row>
                }
                placement="right"
                closable={false}
                open={visible}
                maskClosable={true}
                mask={true}
                className={`${topMenu ? 'Appear-Drawer' : 'Disappear-Drawer'}`}
                rootStyle={{zIndex: '10003'}}
                maskStyle={{backgroundColor: 'transparent'}}
                contentWrapperStyle={{boxShadow: 'none'}}
                width="100%"
            >
                <RightMenu
                    setVisible={setVisible}
                    setNameClassButton={setNameClassButton}
                    menuAbierto = {menuAbierto}
                    setTopMenu={setTopMenu}
                />
            </Drawer>
        </div>
        </>
    )
}

export default Top