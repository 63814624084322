import config from '../../../config'
import { 
    GET_MODULE_DATA, 
    GET_MODULE_DATA_CLONE,
} from '../../../Constants/Modules/CreateEditModules'
import {
    GET_DATA_MODULES,
    LOADING_DATA_MODULES,
} from "../../../Constants/Modules/Modules"
import socket from '../../../App/socket'
import { notification } from 'antd'
import { GET_DATA_MENU_CLONE, GET_DATA_MENU_MODULE_CLONE } from '../../../Constants/GetData/Menu/Menu'
const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetDataModulesReducer = (id_pais) => async (dispatch, getState) => {
    dispatch({
        type: LOADING_DATA_MODULES,
        payload : true
    })

    await fetch(config.api+'modules/get-user-modules-all',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem('usutoken'),
                'middle_usuario' : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_usupais : id_pais ? parseInt(id_pais) : localStorage.getItem('usupais')
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.respuesta){
            const modules_data = data.data.map((mod, indexmod) => {
                if(mod.children){
                    const subchildren = mod.children.map((subc, subindex) => {
                        if(subc.children){
                            const subsubchildren = subc.children.map((subsubc, subsubindex) => {
                                return {
                                    ...subsubc,
                                    key: "subsubmodule-"+(subsubindex+1)
                                }
                            })
                            return {
                                ...subc,
                                children: subsubchildren,
                                key: "submodule-"+(subindex+1)
                            }
                        }else{
                            return {
                                ...subc,
                                key: "submodule-"+(subindex+1)
                            }
                        }
                    })
                    return {
                        ...mod,
                        children: subchildren,
                        collapse: false,
                        key: "module-"+(indexmod+1)
                    }
                }else{
                    return {
                        ...mod,
                        collapse: false,
                        key: "module-"+(indexmod+1)
                    }
                }
            })
            dispatch({
                type: GET_DATA_MODULES,
                payload : modules_data
            })
        }
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type: LOADING_DATA_MODULES,
        payload : false
    })
}

export const DeleteDataModuleReducer = (id) => async (dispatch, getState) => {
    await fetch(config.api+'modules/delete-module',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem('usutoken'),
                'middle_usuario' : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_id : id
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            notificacionServidor('success', data.message)
            dispatch(GetDataModulesReducer())
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const DeleteDataSubModuleReducer = (id) => async (dispatch, getState) => {
    let response = false
    await fetch(config.api+'modules/delete-sub-module',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem('usutoken'),
                'middle_usuario' : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_id : id
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            notificacionServidor('success', data.message)
            response = true
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })
    return response
}

export const DeleteDataSubSubModuleReducer = (id) => async (dispatch, getState) => {
    let response = false
    await fetch(config.api+'modules/delete-sub-sub-module',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem('usutoken'),
                'middle_usuario' : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_id : id
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            notificacionServidor('success', data.message)
            response = true
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })
    return response
}

export const ClearModuleSelectedReducer = () => async (dispatch, getState) => {
    dispatch({
        type: GET_MODULE_DATA,
        payload : []
    })
    dispatch({
        type: GET_MODULE_DATA_CLONE,
        payload : []
    })
}

export const ChangeOrderModulesReducer = (active, over, arrayMove, type) => async (dispatch, getState) => {
    
    if(active.id !== over.id){
        if(type == "menu"){
            const { rex_data_menu_clone, rex_data_menu_module_clone } = getState().menu
            const activeIndex = rex_data_menu_clone.findIndex(i => i.key === active.id)
            const overIndex = rex_data_menu_clone.findIndex(i => i.key === over.id)
            const arrayChange = arrayMove(rex_data_menu_clone, activeIndex, overIndex)
            dispatch({
                type: GET_DATA_MENU_CLONE,
                payload : arrayChange
            })

            const activeIndexModule = rex_data_menu_module_clone.findIndex(i => i.key === active.id)
            const overIndexModule = rex_data_menu_module_clone.findIndex(i => i.key === over.id)
            const arrayChangeModule = arrayMove(rex_data_menu_module_clone, activeIndexModule, overIndexModule)
            dispatch({
                type: GET_DATA_MENU_MODULE_CLONE,
                payload : arrayChangeModule
            })

            dispatch(ChangeOrderMenuBaseReducer(arrayChangeModule))
        }else{
            const { rex_get_data_modules } = getState().modules
            const activeIndex = rex_get_data_modules.findIndex(i => i.key === active.id)
            const overIndex = rex_get_data_modules.findIndex(i => i.key === over.id)
            const arrayChange = arrayMove(rex_get_data_modules, activeIndex, overIndex)
            dispatch({
                type: GET_DATA_MODULES,
                payload : arrayChange
            })
            dispatch(ChangeOrderModulesBaseReducer(arrayChange))
        }
        
    }
}

export const ChangeOrderModulesBaseReducer = (arrayChange) => async () => {
    await fetch(config.api+'modules/edit-order-mod',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem('usutoken'),
                'middle_usuario' : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_data: arrayChange,
            }),
        }
    )
    .then(res => res.json())
    .catch(error => console.log(error))
    socket.emit('edit-module-order', '','');
}

export const ChangeOrderMenuBaseReducer = (arrayChange) => async () => {
    await fetch(config.api+'get-data/edit-order-menu',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem('usutoken'),
                'middle_usuario' : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_data: arrayChange,
            }),
        }
    )
    .then(res => res.json())
    .catch(error => console.log(error))
}

export const ChangeDisabledCollapseModulesReducer = (state, keymod) => async (dispatch, getState) => {
    const { rex_get_data_modules } = getState().modules
    const data_modules = rex_get_data_modules.map(rgdm => {
        if(rgdm.key == keymod){
            return {
                ...rgdm,
                collapse: state ? false : true,
            }
        }else{
            return {...rgdm, collapse: false}
        }
    })
    dispatch({
        type: GET_DATA_MODULES,
        payload : data_modules
    })
}

export const ChangeDisabledStatusModulesReducer = (state, keymod) => async (dispatch, getState) => {
    const { rex_get_data_modules } = getState().modules
    const data_modules = rex_get_data_modules.map(rgdm => {
        if(rgdm.key == keymod){
            return {
                ...rgdm,
                modvisualizacion: state,
            }
        }else{
            return {...rgdm}
        }
    })

    dispatch({
        type: GET_DATA_MODULES,
        payload : data_modules
    })

    const find_mod = rex_get_data_modules.find(mod => mod.key == keymod)
    await fetch(config.api+'modules/edit-display-mod',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem('usutoken'),
                'middle_usuario' : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_modid: find_mod.modid,
                req_modvisualizacion: state,
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            socket.emit('edit-module-order', '','');
        }
    })
    .catch(error => console.log(error))
}

export const ChangeOrderSubModulesReducer = (active, over, arrayMove, keymod) => async (dispatch, getState) => {
    const { rex_get_data_modules } = getState().modules
    if(active.id !== over.id){
        const findModule = rex_get_data_modules.find(i => i.key === keymod)
        const activeIndex = findModule.children.findIndex(sub => sub.key === active.id)
        const overIndex = findModule.children.findIndex(sub => sub.key === over.id)
        const arrayChange = arrayMove(findModule.children, activeIndex, overIndex)
        const array = rex_get_data_modules.map(i => i.key == keymod
            ?   {...i, children: arrayChange}
            :   {...i}
        )
        dispatch({
            type: GET_DATA_MODULES,
            payload : array
        })
        dispatch(ChangeOrderSubModulesBaseReducer(arrayChange))
    }
}

export const ChangeOrderSubModulesBaseReducer = (arrayChange) => async () => {
    await fetch(config.api+'modules/edit-order-submod',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem('usutoken'),
                'middle_usuario' : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_data: arrayChange,
            }),
        }
    )
    .then(res => res.json())
    .catch(error => console.log(error))
    socket.emit('edit-module-order', '','');
}

export const ChangeDisabledCollapseSubModulesReducer = (state, keymod, keysubmod) => async (dispatch, getState) => {
    const { rex_get_data_modules } = getState().modules
    let findModule = rex_get_data_modules.find(i => i.key === keymod)
    const changeSubModule = findModule.children.map(i => i.key === keysubmod ? {...i, smovisualizacion: state} : {...i})
    findModule.children = changeSubModule
    const array = rex_get_data_modules.map(i => {
        if(i.key == findModule.key){
            return {...findModule}
        }else{
            return {...i}
        }
    })
    dispatch({
        type: GET_DATA_MODULES,
        payload : array
    })

    const find_mod = rex_get_data_modules.find(mod => mod.key == keymod)
    const find_submod = find_mod.children.find(smo => smo.key == keysubmod)

    await fetch(config.api+'modules/edit-display-submod',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem('usutoken'),
                'middle_usuario' : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_smoid: find_submod.smoid,
                req_smovisualizacion: state,
            }),
        }
    )
    .then(res => res.json())
    .catch(error => console.log(error))
    socket.emit('edit-module-order', '','');
}

export const ChangeOrderSubSubModulesReducer = (active, over, arrayMove, keymod, keysubmod) => async (dispatch, getState) => {
    const { rex_get_data_modules } = getState().modules
    if(active.id !== over.id){
        const findModule = rex_get_data_modules.find(i => i.key === keymod)
        const findSubModule = findModule.children.find(i => i.key === keysubmod)
        const activeIndex = findSubModule.children.findIndex(sub => sub.key === active.id)
        const overIndex = findSubModule.children.findIndex(sub => sub.key === over.id)
        const arrayChange = arrayMove(findSubModule.children, activeIndex, overIndex)
        const array = rex_get_data_modules.map(i => i.key == keymod
            ?   {
                    ...i, 
                    children: i.children.map(sub => 
                        sub.key == keysubmod
                        ?   {...sub, children: arrayChange}
                        :   {...sub}
                    )
                }
            :   {...i}
        )
        dispatch({
            type: GET_DATA_MODULES,
            payload : array
        })
        dispatch(ChangeOrderSubSubModulesBaseReducer(arrayChange))
    }
}

export const ChangeOrderSubSubModulesBaseReducer = (arrayChange) => async () => {
    await fetch(config.api+'modules/edit-order-subsubmod',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem('usutoken'),
                'middle_usuario' : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_data: arrayChange,
            }),
        }
    )
    .then(res => res.json())
    .catch(error => console.log(error))
}

export const ChangeDisabledCollapseSubSubModulesReducer = (state, keymod, keysubmod, keysubsubmod) => async (dispatch, getState) => {
    const { rex_get_data_modules } = getState().modules
    let findModule = rex_get_data_modules.find(i => i.key === keymod)
    let findSubModule = findModule.children.find(i => i.key === keysubmod)
    const changeSubSubModule = findSubModule.children.map(i => i.key === keysubsubmod ? {...i, ssmvisualizacion: state} : {...i})
    findSubModule.children = changeSubSubModule
    findModule.children = findModule.children.map(fsm => {
        if(fsm.key == findSubModule.key){
            return {...findSubModule}
        }else{
            return {...fsm}
        }
    })
    const array = rex_get_data_modules.map(i => {
            if(i.key == findModule.key){
                return {...findModule}
            }else{
                return {...i}
            }
    })
    dispatch({
        type: GET_DATA_MODULES,
        payload : array
    })

    const find_mod = rex_get_data_modules.find(mod => mod.key == keymod)
    const find_submod = find_mod.children.find(smo => smo.key == keysubmod)
    const find_subsubmod = find_submod.children.find(ssm => ssm.key == keysubsubmod)

    await fetch(config.api+'modules/edit-display-subsubmod',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem('usutoken'),
                'middle_usuario' : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_ssmid: find_subsubmod.ssmid,
                req_ssmvisualizacion: state,
            }),
        }
    )
    .then(res => res.json())
    .catch(error => console.log(error))
}