import config from "../../../../config"
import {
    GET_DATA_MENU,
    GET_DATA_MENU_CLONE,
    GET_DATA_MENU_PROFILE_TYPES_CLONE,
    GET_DATA_MENU_MODULE_CLONE,
} from "../../../../Constants/GetData/Menu/Menu"
import ImgProfile from '../../../../Assets/Images/Menu/ProfileBlue.png'
import ImgHome from '../../../../Assets/Images/Menu/HomeBlue.png'
import ImgStatus from '../../../../Assets/Images/Menu/StatusBlue.png'
import ImgAnalytics from '../../../../Assets/Images/Menu/AnalyticBlue.png'
import ImgDownload from '../../../../Assets/Images/Menu/DownloadBlue.png'
import ImgFileUpload from '../../../../Assets/Images/Menu/UploadBlue.png'
import ImgCategory from '../../../../Assets/Images/Menu/CategoryBlue.png'
import ImgAdministrador from '../../../../Assets/Images/Menu/AdminBlue.png'
import ImgCerrar from '../../../../Assets/Images/Menu/SignOutBlue.png'
import ImgProfileBlanco from '../../../../Assets/Images/Menu/Blancos/ProfileWhite.png'
import ImgHomeBlanco from '../../../../Assets/Images/Menu/Blancos/HomeWhite.png'
import ImgCategoryBlanco from '../../../../Assets/Images/Menu/Blancos/CategoryWhite.png'
import ImgStatusBlanco from '../../../../Assets/Images/Menu/Blancos/StatusWhite.png'
import ImgAnalyticsBlanco from '../../../../Assets/Images/Menu/Blancos/AnalyticWhite.png'
import ImgDownloadBlanco from '../../../../Assets/Images/Menu/Blancos/DownloadWhite.png'
import ImgFileUploadBlanco from '../../../../Assets/Images/Menu/Blancos/UploadWhite.png'
import ImgAdministradorBlanco from '../../../../Assets/Images/Menu/Blancos/AdminWhite.png'
import ImgCerrarBlanco from '../../../../Assets/Images/Menu/Blancos/SignOutWhite.png'
import ImgTeamGrow from '../../../../Assets/Images/Menu/iconoTeam2.png'

export const GetDataMenuReducer = () => async (dispatch) => {
    await fetch(config.api+'get-data/list-menu',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_country : localStorage.getItem('usupais')
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            dispatch({
                type: GET_DATA_MENU,
                payload : data.data
            })

            const data_menu = data.data.map((dat, index) => {
                return {
                    modid: dat.mnuid,
                    modnombre: dat.mnunombre,
                    modicono: dat.mnuicono,
                    paiid: dat.paiid,
                    modvisualizacion: true,
                    key: 'menu-'+(index+1),
                    title: dat.mnunombre,
                    toUrl: dat.mnuruta,
                    children: [],
                    type: 'menu'
                }
            })
            
            dispatch({
                type: GET_DATA_MENU_CLONE,
                payload : data_menu
            })

            dispatch({
                type: GET_DATA_MENU_MODULE_CLONE,
                payload : data_menu
            })

            dispatch(AddImageListMenuReducer())
        }else{
            // dispatch(RegisterAuditReducer('get-data/countries', 'Error al obtener los paises', data.message, localStorage.getItem('usutoken'), data, user.usuid, 'ip', 'GET COUNTRIES'))
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const GetDataMenuAllReducer = () => async (dispatch) => {
    await fetch(config.api+'get-data/list-menu-all',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            dispatch({
                type: GET_DATA_MENU_PROFILE_TYPES_CLONE,
                payload: data.data
            })
        }else{
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const AddImageListMenuReducer = () => async (dispatch, getState) => {
    const { rex_data_menu_clone } = getState().menu

    let menu_data = []

    menu_data.push({
        title: 'Home',
        toUrl:'/home',
        selectedMenu: false,
        component: () => (
            <div className="Container-Home-Menu-Image">
                <img style={{width:'20px'}} src={ImgHome} />
                <img className='Img-Blanco' style={{width:'20px'}} src={ImgHomeBlanco} />
            </div>
        ),
    },
    {
        title: 'Perfil',
        toUrl:'/profile',
        selectedMenu: false,
        component: () => (
            <div className="Container-Profile-Menu-Image">
                <img style={{width:'20px'}} src={ImgProfile} />
                <img className='Img-Blanco' style={{width:'20px'}} src={ImgProfileBlanco} />
            </div>
        ),
    },)

    rex_data_menu_clone.map(menu => {
        if(menu.modnombre == "Categorías"){
            menu_data.push({
                ...menu, 
                selectedMenu: false,
                component: () => (
                    <div className="Container-Status-Menu-Image">
                        <img style={{width:'20px'}} src={ImgCategory} />
                        <img className='Img-Blanco' style={{width:'20px'}} src={ImgCategoryBlanco} />
                    </div>
                )
            })
        }else if(menu.modnombre == "Status"){
            menu_data.push({
                ...menu, 
                selectedMenu: false,
                component: () => (
                    <div className="Container-Status-Menu-Image">
                        <img style={{width:'20px'}} src={ImgStatus} />
                        <img className='Img-Blanco' style={{width:'20px'}} src={ImgStatusBlanco} />
                    </div>
                )
            })
        }else if(menu.modnombre == "Analytics"){
            menu_data.push({
                ...menu, 
                selectedMenu: false,
                component: () => (
                    <div className="Container-Analytics-Menu-Image">
                        <img style={{width:'20px'}} src={ImgAnalytics} />
                        <img className='Img-Blanco' style={{width:'20px'}} src={ImgAnalyticsBlanco} />
                    </div>
                )
            })
        }else if(menu.modnombre == "Download Files"){
            menu_data.push({
                ...menu, 
                selectedMenu: false,
                component: () => (
                    <div className="Container-Download-Menu-Image">
                        <img style={{width:'20px'}} src={ImgDownload} />
                        <img className='Img-Blanco' style={{width:'20px'}} src={ImgDownloadBlanco} />
                    </div>
                )
            })
        }else if(menu.modnombre == "File Upload"){
            menu_data.push({
                ...menu, 
                selectedMenu: false,
                component: () => (
                    <div className="Container-Upload-Menu-Image">
                        <img style={{width:'20px'}} src={ImgFileUpload} />
                        <img className='Img-Blanco' style={{width:'20px'}} src={ImgFileUploadBlanco} />
                    </div>
                )
            })
        }else if(menu.modnombre == "Administrador"){
            menu_data.push({
                ...menu, 
                selectedMenu: false,
                component: () => (
                    <div className="Container-Admin-Menu-Image">
                        <img style={{width:'20px'}} src={ImgAdministrador} />
                        <img className='Img-Blanco' style={{width:'20px'}} src={ImgAdministradorBlanco} />
                    </div>
                )
            })
        }
    })

    // menu_data.push({
    //     id : 20,
    //     title: 'Team Grow',
    //     toUrl:'',
    //     selectedMenu: false,
    //     component: () => (
    //         <div className="Container-Profile-Menu-Image" style={{display:'flex !important', alignItems:'center '}}>
    //             <img style={{width:'20px', marginTop:'5px'}} src={ImgTeamGrow} />
    //             {/* <img className='Img-Blanco' style={{width:'20px'}} src={ImgTeamGrow} /> */}
    //         </div>
    //     ),
    // })

    menu_data.push({
        id : 1,
        title: 'Cerrar sesión',
        toUrl:'',
        selectedMenu: false,
        component: () => (
            <div className="Container-Sing-Out-Menu-Image">
                <img style={{width:'20px'}} src={ImgCerrar} />
                <img className='Img-Blanco' style={{width:'20px'}} src={ImgCerrarBlanco} />
            </div>
        ),
    })



    dispatch({
        type: GET_DATA_MENU_CLONE,
        payload : menu_data
    })
}

export const SelectedMenuReducer = (text) => async (dispatch, getState) => {
    const { rex_data_menu_clone } = getState().menu
    rex_data_menu_clone.map((menu, index) => {
        if(text !== "Cerrar sesión"){
            if(menu.title === text){
                rex_data_menu_clone[index]['selectedMenu'] = true
            }else{
                rex_data_menu_clone[index]['selectedMenu'] = false
            }
        }
    })

    dispatch({
        type: GET_DATA_MENU_CLONE,
        payload : rex_data_menu_clone
    })
}