import React, { useEffect, useState } from 'react'
import { Typography, Modal, Row, Col, Form, Input, Checkbox, Radio, DatePicker, Select, Collapse, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { LoadingOutlined, CaretDownOutlined } from '@ant-design/icons'
import {
    CreateUserReducer,
    CleanDataCreateUserReducer,
    EditUserReducer,
    ChangeUserDataReducer,
    LoadingUsersReducer,
    CheckBoxChangeCountryReducer,
    SelectedCountryUserReducer
} from '../../Redux/Actions/Users/Users'
import { 
    GetModulesProfileReducer,
    GetTypeProfileCountryReducer,
} from '../../Redux/Actions/ProfileTypes/ProfileTypes'
import '../../Styles/Components/CreateUser/FormCreateUser.css'
import dayjs from 'dayjs'
import moment from "moment"
import NotificationErrorServer from './NotificationErrorServer'
import UserModules from './UserModules'

const FormCreateUser = ({edit}) => {
    const [countrySelected, setCountrySelected] = useState(null)
    const [countrySelectedIcon, setCountrySelectedIcon] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [textPhone, setTextPhone] = useState(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { Title } = Typography
    const { Panel } = Collapse
    const { confirm } = Modal

    const { 
        rex_data_paises,
        rex_data_country_clone,
        rex_data_country_total,
    } = useSelector(({paises}) => paises)

    const { 
        rex_data_users_form,
        rex_loading_data_users,
    } = useSelector(({users}) => users)

    const { 
        rex_selected_country_profile_type,
        rex_permissions_country,
    } = useSelector(({profileTypes}) => profileTypes)

    const showConfirmEditUser = async () => {
        let respuesta = false
        respuesta = await confirm({
            title: 'Confirmar',
            className: 'Modal-Edit-User',
            icon:   null,
            content: '¿Estás seguro que desea guardar los cambios?',
            width: 254,
            zIndex: 100000,
            okText: "Confirmar",
            cancelText: "Cancelar",
            okButtonProps: { className: 'Button-Confirm-Edit-User' },
            cancelButtonProps: { className: 'Button-Cancel-Edit-User' },
            onOk() {
                editUser()
            },
            onCancel() {
            },
        })
    }

    const editUser = async () => {
        let respuesta
        dispatch(LoadingUsersReducer(true))
        respuesta = await dispatch(EditUserReducer())

        if(respuesta){
            dispatch(CleanDataCreateUserReducer())
            setModalOpen(true)
        }
        dispatch(LoadingUsersReducer(false))
    }

    const onFinish = async ()  => {
        let respuesta
        if(rex_data_users_form.usuid){
            showConfirmEditUser()
        }else{
            dispatch(LoadingUsersReducer(true))
            respuesta = await dispatch(CreateUserReducer())
        }
        if(respuesta){
            dispatch(CleanDataCreateUserReducer())
            setModalOpen(true)
        }
        dispatch(LoadingUsersReducer(false))
    }

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: '14px',
                color: '#fff'
            }}
            spin
        />
    )

    useEffect(() => {
        if (window.location.hash === "/create-user") {
            dispatch(CleanDataCreateUserReducer())
        }
        dispatch(GetTypeProfileCountryReducer())
        dispatch(GetModulesProfileReducer(rex_data_users_form.usuid))
    }, [])

    const cancelForm = (e) => {
        e.preventDefault()
        dispatch(CleanDataCreateUserReducer())
        navigate('/administrator/users')
    }
    
    return (
        <>
            <Form
                layout='vertical'
                autoComplete='off'
                className="Form-Container-User"
            >
                <Row>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:12}} xl={{span:12}}
                        className='Form-Create-User-Col-Left'
                    >
                        <Form.Item
                            label='Nombres'
                            className='Create-User-Form-Item-Text'
                        >
                            <Input 
                                placeholder="Nombres" 
                                size='large' 
                                onChange={(e) => dispatch(ChangeUserDataReducer(e.target.value, "pernombre"))}
                                value={rex_data_users_form.perpersonas.pernombre}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Apellidos'
                            className='Create-User-Form-Item-Text'
                        >
                            <Input
                                placeholder="Apellidos"
                                size='large'
                                onChange={(e) => dispatch(ChangeUserDataReducer(e.target.value, "perapellidos"))}
                                value={rex_data_users_form.perpersonas.perapellidos}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Género'
                            className='Create-User-Form-Item-Text'
                        >
                            <Select
                                placeholder="Seleccionar género"
                                className="Create-User-Form-Gender"
                                size="large"
                                suffixIcon={<CaretDownOutlined className="Icon-Gender-Form" />}
                                popupClassName="Dropdown-User-Form-Gender"
                                onChange={(e) => dispatch(ChangeUserDataReducer(e, "gnrid"))}
                                options={[
                                    {
                                        value: 1,
                                        label: 'Femenino',
                                    },
                                    {
                                        value: 2,
                                        label: 'Masculino',
                                    }
                                ]}
                                value={rex_data_users_form.gnrid}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Correo Corporativo'
                            className='Create-User-Form-Item-Text'
                        >
                            <Input 
                                placeholder="Ingresar correo"
                                size='large'
                                onChange={(e) => dispatch(ChangeUserDataReducer(e.target.value, "usuusuario"))}
                                value={rex_data_users_form.usuusuario}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Fecha de incorporación'
                            className='Create-User-Form-Item-Text'
                        >
                            <DatePicker
                                placeholder='DD/MM/AAAA'
                                size='large'
                                format='DD/MM/YYYY'
                                onChange={(e, date) => dispatch(ChangeUserDataReducer(date, "usufechaincorporacion"))}
                                value={rex_data_users_form.usufechaincorporacion ? dayjs(moment(rex_data_users_form.usufechaincorporacion).format('DD/MM/YYYY'), 'DD/MM/YYYY') : dayjs(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY')}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:12}} xl={{span:12}}
                        className='Form-Create-User-Col-Right'
                    >
                        <Form.Item
                            label='Correo Personal'
                            className='Create-User-Form-Item-Text'
                        >
                            <Input 
                                placeholder="Ingresar correo"
                                size='large'
                                onChange={(e) => dispatch(ChangeUserDataReducer(e.target.value, "usucorreo"))}
                                value={rex_data_users_form.usucorreo}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Teléfono'
                            className='Create-User-Form-Item-Text'
                        >
                            <Collapse 
                                className="Collapse-Form-User" 
                                expandIconPosition='end'
                            >
                                <Panel 
                                    showArrow={false}
                                    key={'1'}
                                    className="Header-Form-User" 
                                    header={
                                        <div style={{display:'flex', alignItems:'center', columnGap:'5px'}}>
                                            {
                                                countrySelectedIcon != null
                                                ?   <img className='Image-Country-Select' src={countrySelectedIcon} />
                                                :   rex_data_country_total.filter(coun => coun.paiprefijo != null).map(coun => {
                                                        if(rex_data_users_form.usutelefono){
                                                            if(coun.paiprefijo == rex_data_users_form.usutelefono.split(' ')[0]){
                                                                return <img className='Image-Country-Select' src={coun.paiicono} />
                                                            }
                                                        }
                                                    })  
                                            }
                                            <CaretDownOutlined style={{color:'#3B72FF'}}/>
                                            <span>
                                                {
                                                    rex_data_users_form.usutelefono
                                                        ?   rex_data_users_form.usutelefono.split(' ')[0]
                                                        :   ''
                                                }
                                            </span>
                                            <Input 
                                                placeholder="Ingresar teléfono"
                                                size='large'
                                                className='Input-Phone-Select'
                                                onClick={(e) => [
                                                    e.stopPropagation()
                                                ]}
                                                onChange={(e) => {
                                                    const phone_number = e.target.value ? e.target.value : ""
                                                    const number_value = countrySelected != null ? countrySelected : rex_data_users_form?.usutelefono.split(' ')[0]
                                                    setTextPhone(phone_number)
                                                    dispatch(ChangeUserDataReducer(number_value + ' ' + phone_number, "usutelefono"))
                                                }}
                                                value={
                                                    rex_data_users_form.usutelefono
                                                    ?   rex_data_users_form.usutelefono.split(' ')[1]
                                                    :   ''
                                                }
                                            />
                                        </div>
                                        }
                                >
                                    <div className='Collapse-Body'>
                                        {
                                            rex_data_country_total.filter(coun => coun.paiprefijo != null).map(cou => {
                                                return <div 
                                                    onClick={() => {
                                                        setCountrySelected(cou.paiprefijo)
                                                        setCountrySelectedIcon(cou.paiicono)
                                                        const text_value = textPhone != null ? textPhone : rex_data_users_form?.usutelefono.split(' ')[1]
                                                        dispatch(ChangeUserDataReducer(cou.paiprefijo + ' ' + text_value, "usutelefono"))
                                                    }} 
                                                    className='Container-Option-Select-Country'
                                                >
                                                    <img className='Image-Country-Select Country-Select' src={cou.paiicono} />
                                                    <span>{cou.painombre}&nbsp;</span>
                                                    <span>{cou.paiprefijo}</span>
                                                </div>
                                            })
                                        }
                                    </div>
                                </Panel>
                            </Collapse>
                        </Form.Item>
                        <Form.Item
                            label='Contraseña'
                            className='Create-User-Form-Item-Text'
                        >
                            <Input.Password
                                placeholder="Contraseña de 8 caracteres"
                                size='large'
                                className='Create-User-Input-Password'
                                onChange={(e) => dispatch(ChangeUserDataReducer(e.target.value, "contrasenia"))}
                                value={rex_data_users_form.contrasenia}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Área'
                            className='Create-User-Form-Item-Text'
                        >
                            <Input
                                placeholder="Nombre de área"
                                size='large'
                                onChange={(e) => dispatch(ChangeUserDataReducer(e.target.value, "usuarea"))}
                                value={rex_data_users_form.usuarea}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Estado'
                            className='Create-User-Form-Item-Text Radio-Form-State'
                        >
                            <Radio.Group 
                                className='Create-User-Container-Checkbox-Status'
                                value={rex_data_users_form.estid}
                                onChange={(e) => dispatch(ChangeUserDataReducer(e.target.value, "estid"))}
                            >
                                <Radio value={1}>Activo</Radio>
                                <Radio value={2}>Inactivo</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:24}} xl={{span:24}}
                    >
                        <Title className="Permissions-User-Modules">Seleccionar los permisos</Title>
                        <Form.Item
                            label='Acceso nivel país'
                            className='Form-User-Checkbox-Countries'
                        >
                            <Checkbox.Group
                                className='Create-User-Container-Checkbox-Countries'
                                options={rex_data_paises}
                                value={rex_data_users_form.accessCountry}
                                onChange={(checkedValues) => dispatch(CheckBoxChangeCountryReducer(checkedValues))}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Permisos por módulos'
                            className='Form-Item-Checkbox-Countries-Permissions'
                        >
                            <div className="Container-Row-Buttons-Countries-Permissions Container-Row-Buttons-Country-Permissions-Selected">
                            {
                                rex_data_country_clone.map(country => {
                                    const findModule = rex_permissions_country.length > 0 
                                    ? rex_permissions_country.find(cou => cou == country.value)
                                    : false
                                    return (
                                        <div
                                            key={country.value}
                                            className={`Container-Button-Country-Permissions 
                                                ${country.value == rex_selected_country_profile_type
                                                ? 'Container-Button-Country-Permissions-Selected' 
                                                : ''}
                                            `}
                                        >
                                            <div 
                                                className={`Button-Country-Permissions 
                                                    ${findModule
                                                        ? 'Country-Permissions-Added'
                                                        : 'Country-Permissions-Disabled'}
                                                    ${country.value == rex_selected_country_profile_type 
                                                        ? 'Country-Permissions-Selected' 
                                                        : ''}
                                                `}
                                                onClick={() => dispatch(SelectedCountryUserReducer(country.value))}
                                            >
                                                {country.label}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </Form.Item>
                    </Col>
                    <UserModules />
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:24}} xl={{span:24}}
                    >
                        <Form.Item className="Form-Button-Users">
                            <button
                                className='Create-User-Form-Container-Button Create-User-Form-Button-Cancel'
                                onClick={(e) => cancelForm(e)}
                                type='primary'
                            >
                                <span>Cancelar</span>
                                <span className='Text-Span-Cancel-Create-User'>Cancelar</span>
                            </button>
                            <button
                                className='Create-User-Form-Container-Button Create-User-Form-Button-Save'
                                type="primary" 
                                onClick={() => onFinish()}
                            >
                                <span className='Text-Span-Save-User-First'>Guardar</span>
                                <span className='Text-Span-Save-Create-User'>Guardar</span>
                                {
                                    rex_loading_data_users && <Spin className="Spin-Loading" indicator={antIcon} />
                                }
                            </button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <NotificationErrorServer/>

            <Modal 
                title={null}
                centered={true}
                open={modalOpen}
                closable={false}
                width={264}
                footer={null}
                zIndex={100000000}
                className='Modal-Notification-User-Created'
            >
                <Row className='Modal-Container-Text-User-Created'>
                    <Col span={24} className='Modal-Container-Text-Main'>
                        <Title>
                            ¡Listo!
                        </Title>
                    </Col>
                    <Col span={24} className='Modal-Container-Text-Description'>
                        {
                            edit
                            ? <p>El usuario fue editado con éxito</p>
                            : <p>El usuario fue creado con éxito</p>
                        }
                    </Col>
                    <Col span={24}>
                        <div
                            onClick={() => navigate('/administrator/users')}
                            className='Notification-Button-User Notification-Button-User-Created'
                        >
                            Ok
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default FormCreateUser