import config from '../../../config'
import { 
    GET_DATA_PLATFORM_ADMINISTRATION,
    CREATE_UPDATE_PLATFORM_ADMINISTRATION,
    LOADING_PLATFORM_ADMINISTRATION,
} from '../../../Constants/PlatformAdministration/PlatformAdministration'
import { notification } from 'antd'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetDataPlatformAdministrationReducer = () => async (dispatch, getState) => {
    dispatch({
        type    : LOADING_PLATFORM_ADMINISTRATION,
        payload : true
    })
    
    await fetch(config.api+'platform-administration/get-platform-administration',
        {
            mode:'cors',
            method: 'GET',
            headers: {
                'Accept' 	    : 'application/json',
                'Content-type'  : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            const data_pla = data.data ? data.data : {}
            dispatch({
                type    : CREATE_UPDATE_PLATFORM_ADMINISTRATION,
                payload : data_pla
            })
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type    : LOADING_PLATFORM_ADMINISTRATION,
        payload : false
    })
}

export const CreateUpdatePlatformAdministrationReducer = (imgUrl) => async (dispatch, getState) => {
    const { rex_create_update_platform_administration } = getState().platformAdministration
    await fetch(config.api+'platform-administration/create-update-platform-administration',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	    : 'application/json',
                'Content-type'  : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_title_platform: rex_create_update_platform_administration.apltitulo,
                req_icono: imgUrl,
            })
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            dispatch(GetDataPlatformAdministrationReducer())
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })
}