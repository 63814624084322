import { redirect } from 'react-router-dom';
import notificationGlobal from '../../../Components/Global/NotificationGlobal'
import config from '../../../config'
import socket from '../../../App/socket'
import {
    ERROR_USER_LOGIN, 
    SAVE_DATA_USER_LOGIN,
    LOADING_USER_LOGIN,
    STATUS_MENU_OPEN,
    DATA_PERMISSIONS_USER,
    GET_DATA_COUNTRIES_LOGIN,
} from '../../../Constants/LoginGrow/LoginGrow'
import { RegisterAuditReducer } from '../Audits/Audits'
import { GetAudioGreetingReducer } from '../Preload/Preload';
import { GetDataMenuReducer } from '../GetData/Menu/Menu';

export const LoginUsuarioLoginGrowReducer = (usu) => async ( dispatch ) => {

    dispatch({
        type: LOADING_USER_LOGIN,
        payload : true
    })

    let respuesta = false
    
    await fetch(config.api_public+'login',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
            },
            body: JSON.stringify(usu),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(async data => {
        if(data.respuesta){
            respuesta = true
            localStorage.setItem('usutoken', data.auth_token)
            localStorage.setItem('usupais', usu.req_paiid)

            dispatch({
                type: SAVE_DATA_USER_LOGIN,
                payload : data.user
            })

            const nameUser = data.user.perpersonas.pernombre
            const nameFirst = nameUser.split(" ")

            socket.emit('reg-login', data.user);

            localStorage.setItem('usuusuario', nameFirst ? nameFirst[0] : "")
            
            dispatch({
                type    : DATA_PERMISSIONS_USER,
                payload : data.user.permisos
            })

            dispatch({
                type: ERROR_USER_LOGIN,
                payload : {}
            })
            
            dispatch(GetDataMenuReducer())
            const urlAudio = await dispatch(GetAudioGreetingReducer())
            const newAudio = new Audio(urlAudio)
            newAudio.play()
            localStorage.removeItem("pairestore");
        }else{
            if(data.alert){
                dispatch({
                    type: ERROR_USER_LOGIN,
                    payload : data.alert
                })
            }
            const json_audit = {
                url : 'login',
                ip : '',
                json_input  : usu,
                json_output : data,
                description : data.message,
                message     : data.message,
                action      : "LOGIN",
                route       : "/login-grow",
                status      : false,
                module      : "Login Grow"
            }
            dispatch(RegisterAuditReducer(json_audit))
        }
    }).catch((error)=> {
        notificationGlobal('warning', 'Ha ocurrido un error en el servidor')
        console.log(error)
    })

    dispatch({
        type: LOADING_USER_LOGIN,
        payload : false
    })

    if(localStorage.getItem('presentation')){
        setTimeout(() => {
            localStorage.removeItem('presentation')
        }, 4000)
    }else{
        setTimeout(() => {
            localStorage.setItem('presentation', true)
        }, 4000)
    }

    return respuesta
}

export const StatusMenuOpenReducer =  ( open ) => async ( dispatch ) => {
    dispatch({
        type : STATUS_MENU_OPEN,
        value : open
    })
}

export const RecoverPasswordLoginGrowReducer = (usucorreo) => async ( dispatch ) => {

    let respuesta   = false
    let message     = ""

    let paiid = localStorage.getItem('pairestore')

    if(!paiid){
        paiid = 1
    }

    await fetch(config.api_public+'request-password',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
            },
            body: JSON.stringify({
                req_usucorreo   : usucorreo,
                req_paiid       : paiid
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(async data => {
        if(data.response){
            respuesta = true
        }
        message = data.message
    }).catch((error)=> {
        notificationGlobal('warning', 'Ha ocurrido un error en el servidor')
        console.log(error)
    })

    return {respuesta, message}
}
export const RestorePasswordLoginGrowReducer = (token, password, paiid) => async ( dispatch ) => {

    let respuesta   = false
    let message     = false
    
    await fetch(config.api_public+'restore-password',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : token
            },
            body: JSON.stringify({
                req_password : password,
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(async data => {
        if(data.response){
            respuesta = true
            socket.emit('logout', data.usucorreo);
            let responseLogin = await dispatch(LoginUsuarioLoginGrowReducer({req_usucorreo: data.usucorreo, req_usucontrasenia: password, req_paiid: parseInt(paiid)}))
            if(responseLogin){
                respuesta = true
            }
        }else{
            message = data.message
        }
    }).catch((error)=> {
        notificationGlobal('warning', 'Ha ocurrido un error en el servidor')
        console.log(error)
    })

    return { respuesta, message }
}

export const GetCountriesLoginReducer = () => async (dispatch) => {
    await fetch(config.api_public+'get-data-countries-login',
        {
            mode:'cors',
            method: 'GET',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
            },
        }
    )
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            dispatch({
                type : GET_DATA_COUNTRIES_LOGIN,
                payload : data.data
            })
        }else{
            // notificationGlobal('warning', data.message)
        }
    }).catch((error)=> {
        // notificationGlobal('warning', 'Ha ocurrido un error en el servidor')
        console.log(error)
    })
}