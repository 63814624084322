import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Pagination, Row, Table, Col, Typography, Input, Form, Radio, notification, Spin } from 'antd'
import { 
    ChangeCountriesReducer,
    ClearCountriesReducer,
    CreateUpdateCountriesReducer,
    GetCountriesReducer,
    UpdateCountriesValuesReducer, 
} from '../../Redux/Actions/Country/Country'
import { 
    FilterOutlined,
    LoadingOutlined,
} from '@ant-design/icons'
import NavigationGlobal from '../../Components/Global/NavigationGlobal'
import SimpleTableGlobal from '../../Components/Global/SimpleTableGlobal'
import Picture from '../../Assets/Images/Modules/Picture.png'

const Country = () => {
    const [pageTableGlobal, setPageTableGlobal] = useState(1)
    const [pageSizeTableGlobal, setPageSizeTableGlobal] = useState(20)
    const [imageUrl, setImageUrl] = useState()
    const [imageUrlCircular, setImageUrlCircular] = useState()
    const [loadingCreateUpdate, setLoadingCreateUpdate] = useState(false)

    const { Title } = Typography
    const dispatch = useDispatch()
    const refBtnImagen = useRef(null)
    const refBtnImagenCircular = useRef(null)

    const {
		rex_data_countries,
        rex_create_update_countries,
        rex_type_update_countries,
        rex_loading_countries,
    } = useSelector(({countries}) => countries)

    const notificacionMensaje = (type, mensaje) => {
        if(type === 'success'){
            notification['success']({
                message: 'Éxito',
                description: mensaje,
            })
        }else if(type === 'warning'){
            notification['warning']({
                message: 'Advertencia',
                description: mensaje,
            })
        }else if(type === 'error'){
            notification['error']({
                message: 'Error',
                description: mensaje,
            })
        }
    }

    const menuProfileTypes = [
        {
            url: '/home-administrator',
            nombre: 'Administrador'
        },
        {
            url: '/administrator/country',
            nombre: 'Lista de paises'
        },
    ]

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined/>),
        onFilter: (value, record) => {
            return record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
        }
    })

    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            align: 'center',
            ellipsis : true,
            render: (text, record, index) => (pageTableGlobal - 1) * pageSizeTableGlobal + index + 1,
            width: "40px"
        },
        Table.SELECTION_COLUMN,
        {
            title: 'Pais',
            dataIndex: 'painombre',
            width : '120px',
            ellipsis : true,
            ...getColumnSearchProps('painombre', 'Nombre de País'),
            sorter: (a, b) => a.painombre ? a.painombre.localeCompare(b.painombre) : false,
            showSorterTooltip: false,
            render:(_,record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    {record.painombre}
                </div>
            ),
            align:"center"
        },
        {
            title: 'Prefijo',
            dataIndex: 'paiprefijo',
            width : '120px',
            ellipsis : true,
            ...getColumnSearchProps('paiprefijo', 'Prefijo'),
            sorter: (a, b) => a.paiprefijo ? a.paiprefijo.localeCompare(b.paiprefijo) : false,
            showSorterTooltip: false,
            render:(_,record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    {record.paiprefijo}
                </div>
            ),
            align:"center"
        },
        {
            title: 'Estado',
            dataIndex: 'estid',
            align: 'center',
            sorter: (a, b) => a.estid? a.estid - b.estid : false,
            ellipsis : true,
            showSorterTooltip : false,
            width : '70px',
            render:(_, record) => {

                let statusText = record.estid ==  1 ? 'Activo' : 'Inactivo'
                return <div title={statusText} style={{display:'flex', justifyContent:"space-between"}}>
                    <span
                        className='Table-Name-Status-Global Table-Contain-Text-Global'
                    >
                        {statusText}
                    </span>
                </div>
            },
            filters: [
                {
                  text: 'activo',
                  value: 1,
                },
                {
                  text: 'inactivo',
                  value: 2,
                },
            ],
            onFilter: (value, record) => record.estid == value,
        },
        {
            title: 'Ícono',
            dataIndex: 'paiicono',
            width : '100px',
            ellipsis : true,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    <img style={{width: '40px'}} src={record.paiicono} />
                </div>
            ),
            align:"center"
        },
        {
            title: 'Ícono Circular',
            dataIndex: 'paiiconocircular',
            width : '100px',
            ellipsis : true,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    <img style={{width: '40px'}} src={record.paiiconocircular} />
                </div>
            ),
            align:"center"
        },
    ]

    const actionOnRow = (data) => {
        const data_values = {
            pai: data.paiid,
            name: data.painombre,
            prefijo: data.paiprefijo,
            estado: data.estid,
            icono: data.paiicono,
            iconoCircular: data.paiiconocircular,
        }

        dispatch(UpdateCountriesValuesReducer(data_values))
    }

    const formato = (type) => {
        if(type === 'image/jpeg' || type === 'image/png' || type === 'image/jpg' || type === 'image/gif' || type === 'image/svg'){
            return true
        }else{
            return false
        }
    }

    const imgChangePerfil = (file, type) => {
        if(file){
            const errorType = formato(file.type)
            if(!errorType){
                notificacionMensaje('warning', 'Solo debes cargar archivos JPG/PNG/JPEG,GIF,SVG')
                return false
            }

            const image = new Image()
            image.src = URL.createObjectURL(file)
            image.onload = () => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = function() {
                    const base64 = reader.result
                    if(type == "circular"){
                        setImageUrlCircular(base64)
                    }else{
                        setImageUrl(base64)
                    }
                }
            }
        }
    }

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: '14px',
                color: '#fff'
            }}
            spin
        />
    )

    const onFinish = async () => {
        setLoadingCreateUpdate(true)
        const response = await dispatch(CreateUpdateCountriesReducer(imageUrl, imageUrlCircular))
        setLoadingCreateUpdate(false)
        if(response){
            setImageUrl()
            setImageUrlCircular()
            dispatch(ClearCountriesReducer())
            dispatch(GetCountriesReducer())
        }
    }

    useEffect(() => {
        dispatch(GetCountriesReducer())
    }, [])

    return(
        <div className='Container-Main Container-Users'>
            <Row style={{position: 'relative'}}>
                <Col span={24} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Title className="Title-Users">
                        Lista de Paises
                    </Title>
                </Col>
                <Col span={24} style={{position: 'absolute', top: '6px'}}>
                    <NavigationGlobal menuItems={menuProfileTypes}/>
                </Col>
            </Row>
            <Row className='Users-Container-Actions' style={{alignItems: 'start'}} gutter={[20, 0]}>
                <Col span={14}>
                    <Row>
                        <Col span={24} style={{display: "flex", justifyContent: "end", columnGap:"15px"}}>
                            <Pagination
                                total={rex_data_countries.length}
                                showTotal={(total, range) => `${range[0]}-${range[1]} de ${rex_data_countries.length}`}
                                defaultPageSize={pageSizeTableGlobal}
                                defaultCurrent={pageTableGlobal}
                                current={pageTableGlobal}
                                className='Simple-Table-Pagination-Global'
                                onChange={(page, pageSize) => {
                                    setPageTableGlobal(page == 0? 1 : page)
                                    setPageSizeTableGlobal(pageSize)
                                }}
                            />
                        </Col>
                        <Col span={24}>
                            <SimpleTableGlobal
                                data={rex_data_countries}
                                keyRowAction={null}
                                rowSelection={null}
                                columns={columns}
                                pageTableGlobal={pageTableGlobal}
                                pageSizeTableGlobal={pageSizeTableGlobal}
                                actionEnterRow={() => {}}
                                actionLeaveRow={() => {}}
                                actionOnRow={actionOnRow}
                                loadingData={rex_loading_countries}
                                scrollTable='600px'
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={10}>
                    <Form
                        layout='vertical'
                        autoComplete='off'
                        className="Form-Container-User"
                    >
                        <Row gutter={[10, 0]}>
                            <Col span={12}>
                                <Row>
                                    <Col
                                        xs={24} sm={24} md={24}
                                        className='Form-Create-User-Col-Left'
                                    >
                                        <Form.Item
                                            label='Nombre del país'
                                            className='Create-User-Form-Item-Text'
                                        >
                                            <Input 
                                                placeholder="Nombre del país" 
                                                size='large' 
                                                onChange={(e) => dispatch(ChangeCountriesReducer(e.target.value, 'name'))}
                                                value={rex_create_update_countries?.name}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        xs={24} sm={24} md={24}
                                        className='Form-Create-User-Col-Left'
                                    >
                                        <Form.Item
                                            label='Prefijo'
                                            className='Create-User-Form-Item-Text'
                                        >
                                            <Input 
                                                placeholder="Prefijo" 
                                                size='large' 
                                                onChange={(e) => dispatch(ChangeCountriesReducer(e.target.value, 'prefijo'))}
                                                value={rex_create_update_countries?.prefijo}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        xs={24} sm={24} md={24}
                                        className='Form-Create-User-Col-Left'
                                    >
                                        <Form.Item
                                            label='Estado'
                                            className='Create-User-Form-Item-Text Radio-Form-State'
                                        >
                                            <Radio.Group 
                                                className='Create-User-Container-Checkbox-Status'
                                                onChange={(e) => dispatch(ChangeCountriesReducer(e.target.value, 'estado'))}
                                                value={rex_create_update_countries?.estado}
                                            >
                                                <Radio value={1}>Activo</Radio>
                                                <Radio value={2}>Inactivo</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            label='Cargar de ícono'
                                            className='Create-Data-Control-Form-Item-Text'
                                        >
                                            <div className="Container-Input-Image-Country">
                                                <figure 
                                                    className="Container-Image-Country" 
                                                    onClick={() => refBtnImagen.current.click()}
                                                >
                                                    <input 
                                                        type="file" 
                                                        ref={refBtnImagen} 
                                                        style={{display: 'none'}}
                                                        onChange={(e) => imgChangePerfil(e.target.files[0], "normal")}
                                                    />
                                                    <img 
                                                        src={
                                                            imageUrl
                                                            ?   imageUrl
                                                            :   rex_create_update_countries.icono
                                                                ?   rex_create_update_countries.icono
                                                                :   Picture
                                                        } 
                                                        style={
                                                            rex_create_update_countries.icono || imageUrl
                                                            ? { width: '80px', height: 'auto' }
                                                            : { width: '25px', height: 'auto' }
                                                        }
                                                    />
                                                </figure>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label='Cargar de ícono (Circular)'
                                            className='Create-Data-Control-Form-Item-Text'
                                        >
                                            <div className="Container-Input-Image-Country">
                                                <figure 
                                                    className="Container-Image-Country" 
                                                    onClick={() => refBtnImagenCircular.current.click()}
                                                >
                                                    <input 
                                                        type="file" 
                                                        ref={refBtnImagenCircular} 
                                                        style={{display: 'none'}}
                                                        onChange={(e) => imgChangePerfil(e.target.files[0], "circular")}
                                                    />
                                                    <img 
                                                        src={
                                                            imageUrlCircular
                                                            ?   imageUrlCircular
                                                            :   rex_create_update_countries.iconoCircular
                                                                ?   rex_create_update_countries.iconoCircular
                                                                :   Picture
                                                        } 
                                                        style={
                                                            rex_create_update_countries.iconoCircular || imageUrlCircular
                                                            ? { width: '80px', height: 'auto' }
                                                            : { width: '25px', height: 'auto' }
                                                        }
                                                    />
                                                </figure>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item className="Form-Button-Users">
                                    <button
                                        className='Create-User-Form-Container-Button Create-User-Form-Button-Cancel'
                                        onClick={() => {
                                            dispatch(ClearCountriesReducer())
                                            setImageUrl()
                                            setImageUrlCircular()
                                        }}
                                        type='primary'
                                    >
                                        <span>Limpiar</span>
                                        <span className='Text-Span-Cancel-Create-User'>Limpiar</span>
                                    </button>
                                    <button
                                        className='Create-User-Form-Container-Button Create-User-Form-Button-Save'
                                        type="primary" 
                                        onClick={() => onFinish()}
                                    >
                                        <span className='Text-Span-Save-User-First'>
                                            {rex_type_update_countries ? 'Actualizar' : 'Guardar'}
                                        </span>
                                        <span className='Text-Span-Save-Create-User'>
                                            {rex_type_update_countries ? 'Actualizar' : 'Guardar'}
                                        </span>
                                        {
                                            loadingCreateUpdate && <Spin className="Spin-Loading" indicator={antIcon} />
                                        }
                                    </button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default Country