import {
    GET_DATA_PLATFORM_ADMINISTRATION
} from "../../../../Constants/GetData/PlatformAdministration/PlatformAdministration"

const INIT_STATE = {
    rex_get_data_platform_administration : {},
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DATA_PLATFORM_ADMINISTRATION: {
            return {
                ...state,
                rex_get_data_platform_administration : action.payload,
            }
        }
        default:
            return state;
    }
}
