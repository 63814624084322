import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Analytics from './Analytics/Analytics'
import GraficosAnalytics from './Analytics/Graficos/GraficosAnalytics'
import Dashboards from './Dashboards/Dashboards'
import RegisterUsers from './Dashboards/RegisterUsers'
import DownloadData from './DownloadData/DownloadData'
import DownloadDataInternal from './DownloadDataInternal/DownloadDataInternal'
import Users from './Users/Users'
import Paises from './GetData/Paises/Paises'
import Menu from './GetData/Menu/Menu'
import GetPlatformAdministration from './GetData/PlatformAdministration/PlatformAdministration'
import ProfileTypes from './ProfileTypes/ProfileTypes'
import LoginGrow from './LoginGrow/LoginGrow'
import Modules from './Modules/Modules'
import CreateEditModules from './Modules/CreateEditModules'
import Home from './Home/Home'
import HomeModules from './HomeModules/HomeModules'
import Profile from './Profile/Profile'
import Status from './Status/Status'
import FileUpload from './FileUpload/FileUpload'
import UploadedFiles from './UploadedFiles/UploadedFiles'
import MasterClients from './Manage/MasterClients/MasterClients'
import FiltersClients from './Manage/MasterClients/FiltersClients'
import FilterChannelsExecutives from './ChannelsExecutives/FiltersChannelsExecutives'
import Audtis from './Audits/Audits'
import MasterProducts from './Manage/MasterProducts/MasterProducts'
import FiltersProducts from './Manage/MasterProducts/FiltersProducts'
import ClientsExecutives from './ClientsExecutives/ClientsExecutives'
import ChannelsExecutives from './ChannelsExecutives/ChannelsExecutives'
import IncentivProgram from './IncentivProgram/IncentivProgram'
import DataControl from './DataControl/DataControl'
import MSL from './Manage/MSL/MSL'
import PowerBi from "./PowerBi/PowerBi"
import Filtros from "./PowerBi/Filtros"
import Daily from "./PowerBi/Daily"
import FileControl from "./FileControl/FileControl"
import HomeAdministrator from "./HomeAdministrator/HomeAdministrator"
import Global from "./Global/Global"
import TermsConditions from "./TermsConditions/TermsConditions"
import Countries from "./Countries/Countries"
import PlatformAdministration from "./PlatformAdministration/PlatformAdministration"

const createRootReducer = (history) => combineReducers({
    router                      : connectRouter(history),
    dashboards                  : Dashboards,
    users                       : Users,
    paises                      : Paises,
    menu                        : Menu,
    getPlatformAdministration   : GetPlatformAdministration,
    profileTypes                : ProfileTypes,
    loginGrow                   : LoginGrow,
    registerUsers               : RegisterUsers,
    modules                     : Modules,
    createEditModules           : CreateEditModules,
    downloadData                : DownloadData,
    downloadDataInternal        : DownloadDataInternal,
    home                        : Home,
    homeModules                 : HomeModules,
    profile                     : Profile,
    status                      : Status,
    fileUpload                  : FileUpload,
    analytics                   : Analytics,
    graficosAnalytics           : GraficosAnalytics,
    uploadedFiles               : UploadedFiles,
    masterClients               : MasterClients,
    filtersClients              : FiltersClients,
    masterProducts              : MasterProducts,
    filtersProducts             : FiltersProducts,
    clientsExecutives           : ClientsExecutives,
    filtersChannelsExecutives   : FilterChannelsExecutives,
    audits                      : Audtis,
    msl                         : MSL,
    channelsExecutives          : ChannelsExecutives,
    incentivProgram             : IncentivProgram,
    powerBi                     : PowerBi,
    filtros                     : Filtros,
    daily                       : Daily,
    dataControl                 : DataControl,
    fileControl                 : FileControl,
    homeAdministrator           : HomeAdministrator,
    global                      : Global,
    termsConditions             : TermsConditions,
    countries                   : Countries,
    platformAdministration      : PlatformAdministration,
})

export default createRootReducer
