import {
    GET_DATA_MODULES,
    LOADING_DATA_MODULES,
} from "../../../Constants/Modules/Modules"

const INIT_STATE = {
    rex_get_data_modules         : [],
    rex_loading_data_modules     : false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DATA_MODULES: {
            return {
                ...state,
                rex_get_data_modules : action.payload,
            }
        }
        case LOADING_DATA_MODULES: {
            return {
                ...state,
                rex_loading_data_modules : action.payload,
            }
        }
        default:
            return state;
    }
}