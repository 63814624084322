import {useSelector} from "react-redux";

export default function(permiso, componente)
{
    const {LoginUsuario} = useSelector(({rex_data_user_logingrow}) => rex_data_user_logingrow);

    let permisos = LoginUsuario.permisos

    if(localStorage.getItem('tpuprivilegio') == "todo"){
        return componente
    }

    let tienePermiso = false

    permisos.map((pem) => {
        if(permiso == pem.pemslug){
            tienePermiso = true
        }
    })

    if(tienePermiso){
        return componente
    }else{
        return null
    }
}

export function funPermisoAreaFileUpload( cards, cards_usu = [])
{
    let tienePermiso = false

    let pemsFileUpload = cards_usu.filter( car => car.pemruta == "/file-upload")

    cards.map(car => {
        const existCard = pemsFileUpload.find(pem => pem.pemslug == car.slug)
        if(existCard){
            tienePermiso = true
        }
    })

    if(tienePermiso){
        return true
    }else{
        return false
    }
}

export function funPermisosObtenidos(permisos = [], permiso, componente)
{
    // if(localStorage.getItem('tpuprivilegio') == "todo"){
    //     return componente
    // }

    let tienePermiso = false

    permisos.map((pem) => {
        const permisoExist = pem ? pem.pemslug : null
        if(permisoExist == permiso){
            tienePermiso = true
        }
    })

    if(tienePermiso){
        return componente
    }else{
        return null
    }
}

export function funPermisosObtenidosHome(permisos = [], permiso, componente)
{
    // if(localStorage.getItem('tpuprivilegio') == "todo"){
    //     return componente
    // }

    let tienePermiso = false
    let paiid = localStorage.getItem('usupais')

    permisos.map((pem) => {
        const permisoExist = pem ? pem.pemnombremostrar : null
        if(permisoExist == permiso && pem.paiid == paiid){
            tienePermiso = true
        }
    })

    if(tienePermiso){
        return componente
    }else{
        return null
    }
}

export function funPermisosObtenidosBoolean(permisos = [], permiso)
{
    // if(localStorage.getItem('tpuprivilegio') == "todo"){
    //     return true
    // }

    let tienePermiso = false

    permisos.map(pems => {
        const permisoExist = pems ? pems.pemslug : null
        if(permisoExist == permiso){
            tienePermiso = true
        }
    })

    if(tienePermiso){
        return true
    }else{
        return false
    }
}
