import config from "../../../../config"
import {
    GET_DATA_PLATFORM_ADMINISTRATION,
} from "../../../../Constants/GetData/PlatformAdministration/PlatformAdministration"

export const GetDataPlatformAdministrationReducer = () => async (dispatch) => {
    await fetch(config.api+'get-data/platform-administration',
        {
            mode:'cors',
            method: 'GET',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            dispatch({
                type: GET_DATA_PLATFORM_ADMINISTRATION,
                payload : data.data ? data.data : {}
            })
        }
    }).catch((error)=> {
        console.log(error)
    })
}