import {
    SAVE_DATA_USER_LOGIN,
    ERROR_USER_LOGIN,
    LOADING_USER_LOGIN,
    DATA_PERMISSIONS_USER,
    STATUS_MENU_OPEN,
    GET_DATA_COUNTRIES_LOGIN,
} from "../../../Constants/LoginGrow/LoginGrow"

const INIT_STATE = {
    rex_data_user_logingrow     : [],
    rex_error_user_login        : {},
    rex_loading_user_login      : false,
    rex_data_permissions_user   : [],
    rex_status_menu_open        : false,
    rex_data_countries_login    : [],
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case DATA_PERMISSIONS_USER: {
            return {
                ...state,
                rex_data_permissions_user : action.payload
            }
        }
        case SAVE_DATA_USER_LOGIN: {
            return {
                ...state,
                rex_data_user_logingrow : action.payload
            }
        }
        case ERROR_USER_LOGIN: {
            return {
                ...state,
                rex_error_user_login : action.payload
            }
        }
        case LOADING_USER_LOGIN: {
            return {
                ...state,
                rex_loading_user_login : action.payload
            }
        }
        case STATUS_MENU_OPEN: {
            return {
                ...state,
                rex_status_menu_open : action.payload
            }
        }
        case GET_DATA_COUNTRIES_LOGIN: {
            return {
                ...state,
                rex_data_countries_login : action.payload
            }
        }
        default:
            return state;
    }
}
