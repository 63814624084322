import config from "../../../../config"
import {
    OBTENER_PAISES_FILTRO_DATA,
    OBTENER_PAISES_GET_DATA,
    GET_DATA_COUNTRY_CLONE,
    GET_DATA_COUNTRY_TOTAL,
} from "../../../../Constants/GetData/Paises/Paises"
import { RegisterAuditReducer } from "../../Audits/Audits"
import { GenerateDataMenuReducer } from "../../ProfileTypes/ProfileTypes"

export const ObtenerPaisesGetDataReducer = () => async ( dispatch, getState) => {

    const user = getState().loginGrow.rex_data_user_logingrow
    
    let respuesta = false
    await fetch(config.api+'get-data/countries',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.respuesta){
            dispatch({
                type: OBTENER_PAISES_FILTRO_DATA,
                payload : data.filters
            })
            dispatch({
                type: OBTENER_PAISES_GET_DATA,
                payload : data.data
            })

            const data_country = data.data.map(cou => {
                return {...cou, state: false,}
            })
            dispatch({
                type: GET_DATA_COUNTRY_CLONE,
                payload : data_country
            })

            dispatch({
                type: GET_DATA_COUNTRY_TOTAL,
                payload : data.data_total
            })

            dispatch(GenerateDataMenuReducer())
        }else{
            // dispatch(RegisterAuditReducer('get-data/countries', 'Error al obtener los paises', data.message, localStorage.getItem('usutoken'), data, user.usuid, 'ip', 'GET COUNTRIES'))
        }
    }).catch((error)=> {
        console.log(error)
    });

    return respuesta
}