import config from '../../../config'
import { 
    GET_DATA_COUNTRIES,
    CREATE_UPDATE_COUNTRIES,
    TYPE_UPDATE_COUNTRIES,
    LOADING_COUNTRIES,
} from '../../../Constants/Countries/Countries'
import { notification } from 'antd'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetCountriesReducer = () => async (dispatch, getState) => {
    dispatch({
        type    : LOADING_COUNTRIES,
        payload : true
    })
    
    await fetch(config.api+'countries/get-countries',
        {
            mode:'cors',
            method: 'GET',
            headers: {
                'Accept' 	    : 'application/json',
                'Content-type'  : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            dispatch({
                type    : GET_DATA_COUNTRIES,
                payload : data.data
            })
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type    : LOADING_COUNTRIES,
        payload : false
    })
}

export const ChangeCountriesReducer = (value, type) => async (dispatch, getState) => {
    let values_countries = getState().countries.rex_create_update_countries
    values_countries[type] = value

    dispatch({
        type    : CREATE_UPDATE_COUNTRIES,
        payload : values_countries
    })
}

export const UpdateCountriesValuesReducer = (values) => async (dispatch, getState) => {
    dispatch({
        type    : CREATE_UPDATE_COUNTRIES,
        payload : values
    })
    dispatch({
        type    : TYPE_UPDATE_COUNTRIES,
        payload : true
    })
    
}

export const ClearCountriesReducer = () => async (dispatch, getState) => {
    dispatch({
        type    : CREATE_UPDATE_COUNTRIES,
        payload : {}
    })
    dispatch({
        type    : TYPE_UPDATE_COUNTRIES,
        payload : false
    })
}

export const CreateUpdateCountriesReducer = (icono, iconoCircular) => async (dispatch, getState) => {
    let status = 200
    let response = true

    const {
        rex_create_update_countries,
        rex_type_update_countries,
    } = getState().countries
    
    let json_body = {
        req_nombre: rex_create_update_countries.name,
        req_est: rex_create_update_countries.estado,
        req_prefijo: rex_create_update_countries.prefijo,
        req_icono: icono,
        req_icono_circular: iconoCircular,
    }

    let url_api = "/create-countries"

    if(rex_type_update_countries){
        json_body = {
            req_pai: rex_create_update_countries.pai,
            ...json_body,
        }

        url_api = "/update-countries"
    }

    await fetch(config.api+'countries'+url_api,
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	    : 'application/json',
                'Content-type'  : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify(json_body)
        }
    )
    .then(res => {
        status = res.status
        return res.json()
    })
    .then(data => {
        if(data.response){
            notificacionServidor('success', data.message)
        }else{
            response = false
            if(status == 400){
                notificacionServidor('warning', data.message)
            }else{
                notificacionServidor('error', data.message)
            }
        }
    }).catch((error)=> {
        console.log(error)
    })

    return response
}