export const OBTENER_DATA_MODULOS_DASHBOARDS_TOTAL              = 'OBTENER_DATA_MODULOS_DASHBOARDS_TOTAL'
export const OBTENER_DATA_MODULOS_DASHBOARDS_FIRST              = 'OBTENER_DATA_MODULOS_DASHBOARDS_FIRST'
export const OBTENER_DATA_MODULOS_DASHBOARDS_SECOND             = 'OBTENER_DATA_MODULOS_DASHBOARDS_SECOND'
export const OBTENER_DATA_MODULO_SELECCIONADO_DASHBOARDS        = 'OBTENER_DATA_MODULO_SELECCIONADO_DASHBOARDS'
export const OBTENER_DATA_OTROS_MODULO_SELECCIONADO_DASHBOARDS  = 'OBTENER_DATA_OTROS_MODULO_SELECCIONADO_DASHBOARDS'
export const OBTENER_DATA_ITEMS_SLIDER_GLOBAL_DASHBOARDS        = 'OBTENER_DATA_ITEMS_SLIDER_GLOBAL_DASHBOARDS'
export const OBTENER_ITEMS_OTROS_MODULOS_DASHBOARDS             = 'OBTENER_ITEMS_OTROS_MODULOS_DASHBOARDS'
export const REGISTRAR_ACTIVIDAD_MODULO_DASHBOARDS              = 'REGISTRAR_ACTIVIDAD_MODULO_DASHBOARDS'
export const REGISTRAR_ID_MODULO_SELECCIONADO_DASHBOARDS        = 'REGISTRAR_ID_MODULO_SELECCIONADO_DASHBOARDS'
export const REGISTRAR_DRIID_MODULO_DASHBOARDS                  = 'REGISTRAR_DRIID_MODULO_DASHBOARDS'
export const REGISTRAR_RISID_MODULO_DASHBOARDS                  = 'REGISTRAR_RISID_MODULO_DASHBOARDS'
export const REGISTRAR_TIPO_MODULO_DASHBOARDS                   = 'REGISTRAR_TIPO_MODULO_DASHBOARDS'
export const REGISTER_USERS_CONNECTEDS_DASHBOARDS               = 'REGISTER_USERS_CONNECTEDS_DASHBOARDS'
export const PRELOAD_ANIMATION                                  = 'PRELOAD_ANIMATION'
export const GET_SUBMODULE_DASHBOARD                            = 'GET_SUBMODULE_DASHBOARD'
export const DATA_DASHBOARD                                     = 'DATA_DASHBOARD'
export const TITLE_MODULE                                       = 'TITLE_MODULE'
export const TITLE_SUBMODULE                                    = 'TITLE_SUBMODULE'
export const TITLE_SUBSUBMODULE                                 = 'TITLE_SUBSUBMODULE'
export const TOKEN_ACCESO_POWER_BI                              = 'TOKEN_ACCESO_POWER_BI'
export const ACTIVE_LIST_LEFT                                   = 'ACTIVE_LIST_LEFT'
export const ACTIVE_LIST_RIGHT                                  = 'ACTIVE_LIST_RIGHT'
