import {
    GET_DATA_COUNTRIES,
    CREATE_UPDATE_COUNTRIES,
    TYPE_UPDATE_COUNTRIES,
    LOADING_COUNTRIES,
} from "../../../Constants/Countries/Countries"

const INIT_STATE = {
    rex_data_countries           : [],
    rex_create_update_countries  : {},
    rex_type_update_countries    : false,
    rex_loading_countries        : false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DATA_COUNTRIES: {
            return {
                ...state,
                rex_data_countries : action.payload,
            }
        }
        case CREATE_UPDATE_COUNTRIES: {
            return {
                ...state,
                rex_create_update_countries : action.payload,
            }
        }
        case TYPE_UPDATE_COUNTRIES: {
            return {
                ...state,
                rex_type_update_countries : action.payload,
            }
        }
        case LOADING_COUNTRIES: {
            return {
                ...state,
                rex_loading_countries : action.payload,
            }
        }
        default:
            return state;
    }
}