import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Typography, Spin } from 'antd'
import { useLocation, useParams } from 'react-router'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from "react-router-dom"
import { 
    ObtenerDataModulosDashboardsReducer,
    RegistrarActividadModuloDashboardReducer,
    SeleccionarTipoModuloDashboardsReducer,
    RegistrarEstadoModuloDashboardReducer,
    ClearDashboardReducer
} from '../../Redux/Actions/Dashboards/Dashboards'
import {
    RegisterUsersConnectedReducer
} from '../../Redux/Actions/Dashboards/RegisterUsers'
import { 
    SelectModuleActiveClickHomeModulesReducer, 
    SelectModuleSelectedHomeModulesReducer 
} from '../../Redux/Actions/HomeModules/HomeModules'
import MenuSliderGlobal from '../../Components/Global/MenuSliderGlobal'
import SideMenu from '../../Components/Global/SideMenu'
import socket from '../../App/socket'
import { models } from 'powerbi-client'
import { PowerBIEmbed } from 'powerbi-client-react'
import { Link } from 'react-router-dom'
import PowerBi from '../PowerBi/PowerBi'
import { LoadingOutlined } from '@ant-design/icons'

const Dashboards = () => {
    const dispatch = useDispatch()

    const [habilitarRegistroDashboard, setHabilitarRegistroDashboard] = useState(false)
    const [loadingChangeDashboard, setLoadingChangeDashboard] = useState(false)

    const location = useLocation()
    const { id, idsub, route } = useParams()
    const navigate = useNavigate()
    //Tiempo inactividad usuario: 8 segundos
    const timeout = 300000

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_modulo_seleccionado_dashboards,
        rex_items_menu_slider_global_dashboards,
        rex_title_module,
        rex_title_submodule,
        rex_title_subsubmodule,
        rex_data_dashboard,
        rex_id_selecionado_modulo_dashboards,
        token_acceso_power_bi
    } = useSelector(({dashboards}) => dashboards)

    const { 
        rex_data_user_logingrow
    } = useSelector(({loginGrow}) => loginGrow)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    useEffect(() => {
        
        if(route != "simplePowerbi"){
            // console.log("Ejecuta UseEffect")
            // ObtenerDataModule()
        }

        return () => {
            socket.emit('unreg-dashboards', rex_data_modulo_seleccionado_dashboards, {usuusuario: rex_data_user_logingrow.usuusuario} )
            socket.off('get-users-dashboards')
        };
    },[])

    const ObtenerDataModule = async () => {
        setLoadingChangeDashboard(true)
        let data = await dispatch(ObtenerDataModulosDashboardsReducer(id, idsub, route, true))
        setLoadingChangeDashboard(false)

        if(data){
            await dispatch(SeleccionarTipoModuloDashboardsReducer(idsub))
            dispatch(RegistrarEstadoModuloDashboardReducer('ACTIVO'))
            dispatch(RegistrarActividadModuloDashboardReducer(rex_data_modulo_seleccionado_dashboards, false, location.pathname, id, idsub, route))
            socket.on('get-users-dashboards', (data_users, registrar=false) => {
                if(registrar == false){
                    dispatch(RegisterUsersConnectedReducer(data_users))
                }
            })
    
            return
        }else{
            await dispatch(ClearDashboardReducer())
            navigate(-1)
        }

    }

    const onIdle = () => {
        socket.emit('reg-status-dashboards', rex_data_modulo_seleccionado_dashboards, {usuusuario: rex_data_user_logingrow.usuusuario}, 'ausente' )
        dispatch(RegistrarEstadoModuloDashboardReducer('INACTIVO'))
    }
    const onActive = () => {
        socket.emit('reg-status-dashboards', rex_data_modulo_seleccionado_dashboards, {usuusuario: rex_data_user_logingrow.usuusuario}, 'online' )
        dispatch(RegistrarEstadoModuloDashboardReducer('ACTIVO'))
    }

    useIdleTimer({
        onIdle,
        onActive,
        timeout,
        throttle: 500
    })

    useEffect(() => {
        ObtenerDataModule()
    },[location.pathname])

    useEffect(() => {
        if(habilitarRegistroDashboard){
            dispatch(RegistrarActividadModuloDashboardReducer(rex_data_modulo_seleccionado_dashboards, true, location.pathname, id, idsub, route))
        }
    },[rex_items_menu_slider_global_dashboards])

    useEffect(() => {
        dispatch(RegistrarActividadModuloDashboardReducer(rex_data_modulo_seleccionado_dashboards, true, location.pathname, id, idsub, route))
        const interval = setTimeout(function(){ 
            setHabilitarRegistroDashboard(true)
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    return(
        <>
            <div className="Container-Main Container-Dashboards" style={{paddingBottom: '13px', paddingTop: '49px'}}>
                <Row style={{position: 'relative'}}>
                    <Col span={24}>
                        <Spin 
                            indicator={<LoadingOutlined spin />} 
                            size="small"
                            spinning={loadingChangeDashboard}
                            className='Container-Loading-Route-Dashboard'
                        >
                            <p className="Text-Route-Dashboard">
                                Filtro aplicado: <span onClick={async () => {
                                    await dispatch(SelectModuleSelectedHomeModulesReducer(parseInt(id)))
                                    await dispatch(SelectModuleActiveClickHomeModulesReducer(parseInt(id)))
                                    await dispatch(ClearDashboardReducer())
                                    navigate('/home-modules', { state: parseInt(id) })
                                }} className="Current-Path-Item">{rex_title_module}</span> / {rex_title_subsubmodule 
                                ? <>
                                    <Link to={`/dashboards/modulo/${id}/${rex_data_dashboard.smoruta}`}>
                                        <span className="Current-Path-Item">{rex_title_submodule}</span> / 
                                    </Link>
                                    <Link to={`/dashboards/modulo/${id}/submodulo/${rex_data_dashboard.smoid}/${rex_data_modulo_seleccionado_dashboards.ssmruta}`}>
                                        <span className="Current-Path"> {rex_title_subsubmodule}</span>
                                    </Link>
                                </> 
                                : <Link to={`/dashboards/modulo/${id}/${rex_data_modulo_seleccionado_dashboards.smoruta}`}>
                                    <span className="Current-Path">{rex_title_submodule}</span>
                                </Link>}
                            </p>
                        </Spin>
                    </Col>
                    <div style={{position: 'absolute', right: 0, top: '-6px'}}>
                        <MenuSliderGlobal
                            titleCategory=""
                            items={rex_items_menu_slider_global_dashboards}
                        />
                    </div>
                </Row>
            </div>
            <Row style={{margin: '0 13px 0 30px'}}>
                <Col span={24}>
                    {
                        route == "simplePowerbi"
                        ?   <PowerBi/>
                        : rex_data_modulo_seleccionado_dashboards.smoreportid || rex_data_modulo_seleccionado_dashboards.ssmreportid
                            ?   
                                <PowerBIEmbed
                                    embedConfig = {{
                                        type: 'report',
                                        id: idsub ? rex_data_modulo_seleccionado_dashboards.ssmreportid : rex_data_modulo_seleccionado_dashboards.smoreportid,
                                        embedUrl: idsub ? rex_data_modulo_seleccionado_dashboards.ssmpowerbi : rex_data_modulo_seleccionado_dashboards.smopowerbi,
                                        accessToken: token_acceso_power_bi,
                                        tokenType: models.TokenType.Aad,
                                        settings: {
                                            panes: {
                                                filters: {
                                                    expanded: false,
                                                    visible: false
                                                }
                                            },
                                            background: models.BackgroundType.Default,
                                        }
                                    }}
                                
                                    eventHandlers = {
                                        new Map([
                                            ['loaded', function () {console.log('Report loaded');}],
                                            ['rendered', function () {console.log('Report rendered');}],
                                            ['error', function (event) {console.log(event.detail);}],
                                            ['visualClicked', () => console.log('visual clicked')],
                                            ['pageChanged', (event) => console.log(event)],
                                        ])
                                    }
                                        
                                    cssClassName = { "Class-Power-Bi-Embebed" }
                                
                                    getEmbeddedComponent = { (embeddedReport) => {
                                        let report = embeddedReport;
                                    }}
                                />
                            :   <iframe
                                    id="Iframe-Dashboard"
                                    width="100vw"
                                    height="1110"
                                    src={
                                        idsub
                                        ? rex_data_modulo_seleccionado_dashboards.ssmpowerbi
                                        : rex_data_modulo_seleccionado_dashboards.smopowerbi
                                    }
                                    frameborder="0"
                                    
                                ></iframe> 
                            
                    }
                    <div className="Disguise-Iframe-Footer"></div>
                </Col>
            </Row>
            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </>
    )
}

export default Dashboards