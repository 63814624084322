import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Typography, Form, notification, Spin } from 'antd'
import { 
    CreateUpdatePlatformAdministrationReducer,
    GetDataPlatformAdministrationReducer
} from '../../Redux/Actions/PlatformAdministration/PlatformAdministration'
import { 
    LoadingOutlined
} from '@ant-design/icons'
import NavigationGlobal from '../../Components/Global/NavigationGlobal'
import Picture from '../../Assets/Images/Modules/Picture.png'

const PlatformAdministration = () => {
    const [imageUrl, setImageUrl] = useState()
    const [loadingCreateUpdate, setLoadingCreateUpdate] = useState(false)

    const { Title } = Typography
    const dispatch = useDispatch()
    const refBtnImagen = useRef(null)

    const {
		rex_data_platform_administration,
        rex_create_update_platform_administration,
        rex_loading_platform_administration,
    } = useSelector(({platformAdministration}) => platformAdministration)

    const notificacionMensaje = (type, mensaje) => {
        if(type === 'success'){
            notification['success']({
                message: 'Éxito',
                description: mensaje,
            })
        }else if(type === 'warning'){
            notification['warning']({
                message: 'Advertencia',
                description: mensaje,
            })
        }else if(type === 'error'){
            notification['error']({
                message: 'Error',
                description: mensaje,
            })
        }
    }

    const menuProfileTypes = [
        {
            url: '/home-administrator',
            nombre: 'Administrador'
        },
        {
            url: '/administrator/platform-administration',
            nombre: 'Administrador de Plataforma'
        },
    ]

    const formato = (type) => {
        if(type === 'image/jpeg' || type === 'image/png' || type === 'image/jpg' || type === 'image/gif' || type === 'image/svg'){
            return true
        }else{
            return false
        }
    }

    const imgChangePerfil = (file, type) => {
        if(file){
            const errorType = formato(file.type)
            if(!errorType){
                notificacionMensaje('warning', 'Solo debes cargar archivos JPG/PNG/JPEG,GIF,SVG')
                return false
            }

            const image = new Image()
            image.src = URL.createObjectURL(file)
            image.onload = () => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = function() {
                    const base64 = reader.result
                    setImageUrl(base64)
                }
            }
        }
    }

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: '14px',
                color: '#fff'
            }}
            spin
        />
    )

    const onFinish = async () => {
        setLoadingCreateUpdate(true)
        await dispatch(CreateUpdatePlatformAdministrationReducer(imageUrl))
        setLoadingCreateUpdate(false)
    }

    useEffect(() => {
        dispatch(GetDataPlatformAdministrationReducer())
    }, [])

    return(
        <div className='Container-Main Container-Users'>
            <Row style={{position: 'relative'}}>
                <Col span={24} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Title className="Title-Users">
                        Administrador de la Plataforma
                    </Title>
                </Col>
                <Col span={24} style={{position: 'absolute', top: '6px'}}>
                    <NavigationGlobal menuItems={menuProfileTypes}/>
                </Col>
            </Row>
            <Row className='Users-Container-Actions' style={{alignItems: 'start'}} gutter={[20, 0]}>
                <Col span={24}>
                    <Form
                        layout='vertical'
                        autoComplete='off'
                        className="Form-Container-User"
                    >
                        <Row gutter={[10, 0]}>
                            <Col span={12}>
                                <Form.Item
                                    label='Logo de la Plataforma'
                                    className='Create-Data-Control-Form-Item-Text'
                                >
                                    <div className="Container-Input-Image-Platform-Administration">
                                        <figure 
                                            className="Container-Image-Platform-Administration" 
                                            onClick={() => refBtnImagen.current.click()}
                                        >
                                            <input 
                                                type="file" 
                                                ref={refBtnImagen} 
                                                style={{display: 'none'}}
                                                onChange={(e) => imgChangePerfil(e.target.files[0], "normal")}
                                            />
                                            <img 
                                                src={
                                                    imageUrl
                                                    ?   imageUrl
                                                    :   rex_create_update_platform_administration.aplimagenseleccionado
                                                        ?   rex_create_update_platform_administration.aplimagenseleccionado
                                                        :   Picture
                                                } 
                                                style={
                                                    rex_create_update_platform_administration.aplimagenseleccionado || imageUrl
                                                    ? { width: '80px', height: 'auto' }
                                                    : { width: '35px', height: 'auto' }
                                                }
                                            />
                                        </figure>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item className="Form-Button-Users">
                                    <button
                                        className='Create-User-Form-Container-Button Create-User-Form-Button-Save'
                                        type="primary" 
                                        onClick={() => onFinish()}
                                    >
                                        <span className='Text-Span-Save-User-First'>Actualizar</span>
                                        <span className='Text-Span-Save-Create-User'>Actualizar</span>
                                        {
                                            loadingCreateUpdate && <Spin className="Spin-Loading" indicator={antIcon} />
                                        }
                                    </button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default PlatformAdministration