import {
    GET_DATA_PLATFORM_ADMINISTRATION,
    CREATE_UPDATE_PLATFORM_ADMINISTRATION,
    LOADING_PLATFORM_ADMINISTRATION,
} from "../../../Constants/PlatformAdministration/PlatformAdministration"

const INIT_STATE = {
    rex_data_platform_administration           : {},
    rex_create_update_platform_administration  : {},
    rex_loading_platform_administration        : false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DATA_PLATFORM_ADMINISTRATION: {
            return {
                ...state,
                rex_data_platform_administration : action.payload,
            }
        }
        case CREATE_UPDATE_PLATFORM_ADMINISTRATION: {
            return {
                ...state,
                rex_create_update_platform_administration : action.payload,
            }
        }
        case LOADING_PLATFORM_ADMINISTRATION: {
            return {
                ...state,
                rex_loading_platform_administration : action.payload,
            }
        }
        default:
            return state
    }
}